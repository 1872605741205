import { SvgIcon } from '@ascd/witsby-components';
import { Box } from '@mui/material';

const ChatFeatureIsDisabled = (): JSX.Element => (
  <Box
    sx={{
      p: 1.5,
      height: '90%',
      display: 'flex',
      maxWidth: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}>
    <Box
      sx={(theme) => ({
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        mb: 2,
        justifyContent: 'center',
        color: theme.palette.grey[200],
        fontSize: theme.font.size.SMALL,
        svg: {
          fontSize: theme.font.size.H1,
        },
        div: {
          mt: 1,
          fontSize: theme.font.size.LARGE,
        },
        span: {
          color: theme.palette.grey[300],
        },
      })}>
      <SvgIcon icon="CHAT_ICON" />
      <div>Witsby chat is not enable for your account!</div>
      <span>Please contact your school administrator to start using Witsby chat feature.</span>
    </Box>
  </Box>
);

export default ChatFeatureIsDisabled;

import { ReactNode, useContext } from 'react';
import { I_FONT, I_SIZE, I_BORDER, I_SPACING, I_BOX_SHADOW } from '@ascd/witsby-components';
import { ThemeProvider as MuiThemeProvider, Color, GlobalStyles } from '@mui/material';
import { AppContext } from '@contexts/appContext';
import { getCourseTheme } from '@utils';

declare module '@mui/material/styles' {
  interface Palette {
    white: Partial<Color>;
    black: Partial<Color>;
    yellow: Partial<Color>;
  }
  interface Theme {
    font: I_FONT;
    size: I_SIZE;
    border: I_BORDER;
    spaces: I_SPACING;
    boxShadow: I_BOX_SHADOW;
  }
  interface ThemeOptions {
    font: I_FONT;
    size: I_SIZE;
    border: I_BORDER;
    spaces: I_SPACING;
    boxShadow: I_BOX_SHADOW;
  }
}

const ThemeProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const {
    state: { currentInstitution },
  } = useContext(AppContext);
  const theme = getCourseTheme(currentInstitution?.settings?.theme);

  return (
    <>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      <GlobalStyles
        styles={{
          '&:root': {
            '--toastify-color-warning': '#fda333 !important',
            '--toastify-color-error': `${theme.palette.error.light} !important`,
            '--toastify-color-success': `${theme.palette.primary.main} !important`,
          },
          /* Firefox */
          '@-moz-document url-prefix()': {
            '*': {
              scrollbarWidth: 'thin',
              scrollbarColor: `${theme.palette.primary.main} transparent`,
            },
          },
          /* Handle */
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 0,
            background: theme.palette.primary.main,
          },
          /* Handle on hover */
          '&::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.primary.light,
          },
          '& .lrn': {
            '.lrn-vertical-toc .pagination-flagged.pagination-active>a:before, .toc .lrn-pager li a, .pagination-active':
              {
                backgroundColor: 'transparent !important',
              },
            '.lrn_widget .lrn_valid, .lrn_widget .lrn_selected': {
              backgroundColor: `${theme.palette.primary.main}26 !important`,
            },

            '.lrn-assess-btn.lrn-assess-vertical-link.pagination-active, .toc .lrn-pager li.pagination-active a':
              {
                background: `${theme.palette.primary.main}42 !important`,
              },

            '.lrn-btn-primary, .lrn-primary-button, .lrn-author-ui .lrn-btn-save.lrn-btn-saving, .lrn_btn_blue, .lrn-btn-save-dropdown, .items .progress span, &.lrn-author .lrn-author-nav-tabs .lrn-active-tab-marker, &.lrn-author .lrn-author-api-react-container .lrn-author-checkbox:checked+.lrn-author-checkbox-label:after, .lrn-qe .lrn-qe-ui .lrn-qe-switch .lrn-qe-input:checked+.lrn-qe-switch-trigger, .lrn-qe .lrn-qe-ui .lrn-qe-tile-header, .lrn-qe .lrn-qe-ui .lrn-qe-btn-primary, .lrn-vertical-toc .pagination-active:before, .lrn-vertical-toc .pagination-active a:before, .lrn-horizontal-toc .pagination .pagination-active:before, .lrn-horizontal-toc .lrn-assess-pagination .pagination-active:before, .app-panel.review-screen .item-card.active:before':
              {
                backgroundColor: `${theme.palette.primary.main} !important`,
              },

            '.lrn-vertical-toc .pagination-active:after, .lrn-vertical-toc .pagination-active a:after, .lrn-horizontal-toc .pagination .pagination-active:after, .lrn-horizontal-toc .lrn-assess-pagination .pagination-active:after, .app-panel.review-screen .item-card.active:after':
              {
                borderColor: `transparent transparent transparent ${theme.palette.primary.main} !important`,
              },

            '.lrn-text-small, .lrn-text-small::after, .lrn-qe-i-plus::before, .lrn-i-question::before, .lrn-author-widget-button-hover span, .lrn-author-widget-button-hover span::before, .lrn-author-widget-button:hover .lrn-author-widget-icon:before, .lrn-author-widget-button:hover .lrn-author-widget-text, .lrn-qe .lrn-qe-ui .lrn-qe-nav-pills .lrn-qe-nav-link:hover, .lrn-qe .lrn-qe-ui .lrn-qe-nav-pills .lrn-qe-nav-link:focus, .lrn-author-ui .lrn-list-view-heading:hover, .lrn-author-ui .lrn-list-view-heading:focus, .lrn-author-ui .lrn-btn-default[data-authorapi-selector="back"]:hover, .lrn-qe .lrn-qe-ui .lrn-qe-tabs .lrn-qe-tab-trigger.lrn-active .lrn-qe-form-label-name, .lrn-qe-i-arrow.lrn-qe-inline-block::before, .lrn-qe .lrn-qe-ui .lrn-qe-more-options.lrn-active, .lrn-qe-label span, .lrn-qe .lrn-qe-ui .lrn-qe-toolbar .lrn-qe-toolbar-controls .lrn-qe-checkbox-validate.checked span::before, .lrn-qe .lrn-qe-ui.lrn-qe-toolbar .lrn-qe-toolbar-controls .lrn-qe-checkbox-validate.checked span::before, .lrn-author-ui .lrn-author-activity-edit [data-tab-content=details] .lrn-row-wrapper .lrn-author-row-button.active>.lrn-author-inline, .lrn-author-ui .lrn-author-activity-edit [data-tab-content=details] .lrn-row-wrapper .lrn-author-row-button.active>label, .lrn-author-ui .lrn-author-activity-edit [data-tab-content=settings] .lrn-row-wrapper .lrn-author-row-button.active>.lrn-author-inline, .lrn-author-ui .lrn-author-activity-edit [data-tab-content=settings] .lrn-row-wrapper .lrn-author-row-button.active>label, .lrn-author-ui .lrn-author-toolbar-toggle .lrn-author-toggle-active, .lrn-author-ui .lrn-author-toolbar-toggle .lrn-author-input:not(:checked)~.lrn-author-toggle-right, .lrn-author-ui .lrn-author-toolbar-toggle .lrn-author-input:checked~.lrn-author-toggle-left, .lrn-vertical-toc .pagination-flagged.pagination-active>a:before, .lrn-right-region .right-wrapper .lrn_btn.lrn_active:before':
              {
                color: `${theme.palette.primary.main} !important`,
              },

            'button.lrn_btn.flag-item.pos-middle.lrn_active, button.lrn_btn.test-resource.pos-middle.lrn_active, button.lrn_btn.lrn_btn_drawing.pos-middle.lrn_active, button.lrn_btn.lrn_btn_stickynote.pos-middle.lrn_active, button.lrn_btn.lrn_btn_notepad.pos-middle.lrn_active, button.lrn_btn.test-fullscreen-btn.pos-middle.lrn_active, button.lrn_btn.test-review-screen.pos-middle.lrn_active':
              {
                boxShadow: `inset 4px 0 0 ${theme.palette.primary.main} !important`,
              },

            '.lrn_widget.lrn_mcq .lrn_mcqgroup.lrn_mcqgroup-horizontal .lrn-input, .lrn_widget.lrn_mcq .lrn_mcqgroup.lrn_mcqgroup-vertical .lrn-input':
              {
                accentColor: `${theme.palette.primary.main} !important`,
              },

            '.lrn-qe .lrn-qe-ui .lrn-qe-nav-pills .lrn-qe-nav-link.lrn-active': {
              borderColor: `${theme.palette.primary.main} !important`,
            },

            '.lrn-mcq-option input[type="radio"]:checked:before': {
              color: `${theme.palette.primary.main} !important`,
            },
            '.lrn-horizontal-toc .pagination .lrn-assess-li a:focus, .lrn-horizontal-toc .lrn-assess-pagination .lrn-assess-li a:focus':
              {
                boxShadow: 'none !important',
              },
          },
        }}
      />
    </>
  );
};

export default ThemeProvider;

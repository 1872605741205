import { OperationVariables, QueryHookOptions } from '@apollo/client';
import { IConversation, IParticipants, IUserInfo } from '@ascd/witsby-components';
import { createTheme, ThemeOptions, Theme } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

export const commonConversationsFilter = (
  userId: string,
  searchText: string,
  fetchPolicy = true,
): QueryHookOptions<unknown, OperationVariables> => ({
  variables: {
    userId,
    filter: {
      page: 0,
      limit: 50,
      searchText,
      sortDirection: 'DESC',
      searchField: ['type'],
      sortField: 'updatedAt',
    },
  },
  ...(fetchPolicy && {
    fetchPolicy: 'network-only',
  }),
});

export const findConversationIfAlreadyExist = (
  conversations: IConversation[],
  selectedUserId: string,
) =>
  conversations.find(
    (conversation) =>
      conversation.type === 'DIRECT' &&
      conversation.participants.some(
        (participant: IParticipants) => participant?.userInfo.id === selectedUserId,
      ),
  );

export const commonConversationsData = {
  totalCount: 0,
  conversations: [],
  totalUnreadCount: 0,
  unreadCountByConversations: {},
};

export const getIfParticipantsTyping = (
  typingUsers: {
    [key: string]: IUserInfo[];
  },
  conversationId: string,
  currentUserId: string,
) => {
  if (isEmpty(typingUsers[`${conversationId}`])) {
    return { isTyping: false, users: [] };
  }

  const users = uniqBy(typingUsers[`${conversationId}`], 'id').filter(
    (u) => u.id !== currentUserId,
  );

  if (isEmpty(users)) {
    return { isTyping: false, users: [] };
  }

  return { isTyping: true, users };
};

export const getChatTheme = (THEME: Theme) =>
  createTheme({
    ...THEME,
    palette: {
      ...THEME.palette,
    },
    font: {
      ...THEME.font,
      size: {
        ...THEME.font.size,
        XX_SMALL: '0.67rem',
        X_SMALL: '0.87rem',
        SMALL: '0.97rem',
        REGULAR: '1.17rem',
        LARGE: '1.37rem',
        X_LARGE: '1.67rem',
      },
    },
  } as ThemeOptions);

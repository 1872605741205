export const detectDeviceIsMobile = () => {
  if (typeof window !== 'undefined') {
    const mediaPoint = window.matchMedia('(max-width: 567px)');
    return mediaPoint.matches;
  }
  return false;
};

export const detectDeviceIsTablet = () => {
  if (typeof window !== 'undefined') {
    const mediaPoint = window.matchMedia('(max-width:900px)');
    return mediaPoint.matches;
  }
  return false;
};

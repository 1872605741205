import { ReactNode, useContext } from 'react';
import { useRouter } from 'next/router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Tooltip } from '@mui/material';
import { ChatContext, eChatActionType } from '@contexts/chatContext';

interface IChatLayout {
  title: string;
  type?: string;
  children: ReactNode;
  titleIcon?: ReactNode;
  titleClick?: () => void;
  headerChildren?: ReactNode;
}

const ChatLayout = ({
  title,
  children,
  titleIcon,
  titleClick,
  headerChildren,
  type = 'CHAT',
}: IChatLayout): JSX.Element => {
  const router = useRouter();
  const isPopUpChat = router.pathname !== '/chat-and-communication';

  const { dispatch } = useContext(ChatContext);

  const handleShowConversationList = () => {
    dispatch({
      data: {},
      type: 'DETAILS_PAGE' as eChatActionType.RESET,
    });
  };

  return (
    <Box
      sx={(theme) => ({
        height: '100%',
        overflow: 'hidden',
        ...(!isPopUpChat && {
          borderStyle: 'solid',
          borderWidth: theme.border.width[1],
          borderColor: theme.palette.grey[500],
          borderRadius: theme.border.radius.REGULAR,
        }),
      })}>
      <Box
        sx={(theme) => ({
          p: 1.5,
          pl: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: isPopUpChat ? '#F6F6F6' : theme.palette.grey[500],
        })}>
        <Box
          {...(titleClick && {
            onClick: () => titleClick(),
          })}
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.light,
            maxWidth: type === 'CHAT_DETAILS' ? '100%' : '80%',
            // whiteSpace: 'nowrap',
            // overflow: 'hidden',
            // textOverflow: 'ellipsis',
            svg: {
              mr: 0.5,
              path: {
                fill: theme.palette.primary.light,
              },
              '&.back-arrow': {
                path: {
                  fill: theme.palette.grey[700],
                },
              },
            },
            ...(titleClick && {
              cursor: 'pointer',
            }),
          })}>
          {!isPopUpChat && (
            <ArrowBackIcon
              tabIndex={0}
              className="back-arrow"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleShowConversationList();
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  handleShowConversationList();
                }
              }}
              sx={(theme) => ({
                mr: 1.5,
                cursor: 'pointer',
                fontSize: theme.font.size.LARGE,
                '&:focus': {
                  borderRadius: '50%',
                  outlineColor: theme.palette.primary.main,
                },
                [theme.breakpoints.up('sm')]: {
                  display: 'none',
                },
              })}
            />
          )}
          {titleIcon}
          {type === 'CHAT_DETAILS' ? (
            <Box component="span">{title}</Box>
          ) : (
            <Tooltip describeChild title={title}>
              <Box
                component="span"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  textOverflow: 'ellipsis',
                }}>
                {title}
              </Box>
            </Tooltip>
          )}
        </Box>
        <Box
          sx={(theme) => ({
            width: theme.size.LARGE,
            height: theme.size.LARGE,
          })}>
          {headerChildren}
        </Box>
      </Box>
      <Box sx={{ height: 'calc(100% - 49px)' }}>{children}</Box>
    </Box>
  );
};

export default ChatLayout;

import AvatarGroup, { AvatarGroupProps } from '@mui/material/AvatarGroup';
import { Avatar } from '../Avatar';

interface IGroupAvatars extends AvatarGroupProps {
  avatars: {
    src: string;
    name: string;
  }[];
}

const GroupAvatars = ({ avatars, ...restProps }: IGroupAvatars): JSX.Element => (
  <AvatarGroup
    data-testid="avatar-group"
    max={5}
    sx={(theme) => ({
      cursor: 'pointer',
      '.MuiAvatar-root': {
        width: theme.size.LARGE,
        height: theme.size.LARGE,
        color: theme.palette.grey[100],
        fontSize: theme.font.size.X_SMALL,
        fontWeight: theme.font.weight.BOLD,
        background: theme.palette.grey[500],
      },
    })}
    {...restProps}>
    {avatars?.map((avatar, index) => (
      <Avatar src={avatar.src} alt={avatar.name} key={`${avatar.name}_${index + 1}`} />
    ))}
  </AvatarGroup>
);

export default GroupAvatars;

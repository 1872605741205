import { useContext } from 'react';
import { IConversation, SvgIcon } from '@ascd/witsby-components';
import GroupsIcon from '@mui/icons-material/Groups';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import get from 'lodash/get';
import { ChatContext, eChatActionType } from '@contexts/chatContext';
import { Avatar } from '../Avatar';
import { ChatLayout } from '../ChatLayout';
import { GroupAvatars } from '../GroupAvatars';
import { Messages } from '../Messages';

const Chat = (): JSX.Element => {
  const {
    dispatch,
    state: { detailsPage },
  } = useContext(ChatContext);

  const conversation: IConversation = get(
    detailsPage,
    'detailsPageData.conversation',
    {},
  ) as IConversation;

  const onChatDetailsClick = () => {
    dispatch({
      data: {
        type: 'CHAT_DETAILS',
        detailsPageData: { conversation },
      },
      type: 'DETAILS_PAGE' as eChatActionType.DETAILS_PAGE,
    });
  };

  return (
    <ChatLayout
      type="CHAT"
      title={conversation.title}
      titleClick={onChatDetailsClick}
      {...(conversation.type === 'DIRECT' && {
        titleIcon: (
          <Avatar
            alt={conversation.title}
            key={conversation.avatar}
            src={conversation.avatar}
            sx={(theme) => ({
              mr: 1,
              width: theme.size.LARGE,
              height: theme.size.LARGE,
              fontSize: theme.font.size.SMALL,
            })}
          />
        ),
        headerChildren: (
          <ManageAccountsIcon
            tabIndex={0}
            onClick={onChatDetailsClick}
            sx={(theme) => ({
              cursor: 'pointer',
              color: theme.palette.grey[100],
              '&:focus': {
                borderRadius: '50%',
                outlineColor: theme.palette.primary.main,
              },
            })}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                onChatDetailsClick();
              }
            }}
          />
        ),
      })}
      {...(conversation.type !== 'DIRECT' && {
        titleIcon:
          conversation.type === 'GROUP' ? (
            <GroupsIcon />
          ) : (
            <SvgIcon
              viewBox="0 0 16 17"
              icon="CONVERSATION_ICON"
              sx={(theme) => ({
                fontSize: theme.font.size.LARGE,
              })}
            />
          ),
        headerChildren: (
          <GroupAvatars
            tabIndex={0}
            onClick={onChatDetailsClick}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                onChatDetailsClick();
              }
            }}
            avatars={conversation?.participants?.map((participant) => ({
              name: participant?.userInfo?.name || '',
              src: participant?.userInfo?.avatarUrl || '',
            }))}
          />
        ),
      })}>
      <Messages />
    </ChatLayout>
  );
};

export default Chat;

import { useCallback, useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  IIcons,
  SvgIcon,
  Typography,
  CourseOutlineHeader,
  CourseOutlineInstitute,
} from '@ascd/witsby-components';
import { Grid, SxProps, Theme, useTheme } from '@mui/material';
import { AppContext } from '@contexts/appContext';
import GET_IMAGE_URL from '@graphql/schema/getImageUrl.graphql';

interface IPagesSubHeader {
  title: string;
  titleViewBox?: string;
  titleIcon?: keyof IIcons;
  iconSx?: SxProps<Theme>;
}

const PagesSubHeader = ({ title, titleIcon, titleViewBox, iconSx }: IPagesSubHeader) => {
  const apolloClient = useApolloClient();

  const {
    state: { currentInstitution },
  } = useContext(AppContext);

  const [logoUrl, setLogoUrl] = useState('');

  const theme1 = useTheme();

  const fetchImage = useCallback(
    async (imageName: string) => {
      const MINUTE_MS = 60000;
      const defaultCacheImages = localStorage.getItem('images');
      let localStorageImages = defaultCacheImages ? JSON.parse(defaultCacheImages) : {};
      if (localStorageImages[`${imageName}`]) return localStorageImages[`${imageName}`].image;
      const response = await apolloClient.query({
        query: GET_IMAGE_URL,
        variables: { imageName },
        fetchPolicy: 'network-only',
      });

      const image = response?.data?.getImageUrl?.url || '';
      const currentTime = new Date();
      localStorageImages = {
        ...localStorageImages,
        [imageName]: {
          image,
          expiry: currentTime.getTime() + MINUTE_MS * 14,
        },
      };

      localStorage.setItem('images', JSON.stringify(localStorageImages));
      return image;
    },
    [apolloClient],
  );

  useEffect(() => {
    if (currentInstitution.iconSrc) {
      const isValidUrl =
        currentInstitution.iconSrc.startsWith('http') ||
        currentInstitution.iconSrc.startsWith('https');
      if (isValidUrl) {
        setLogoUrl(currentInstitution.iconSrc);
      } else {
        fetchImage(currentInstitution.iconSrc).then((url) => setLogoUrl(url));
      }
    } else {
      setLogoUrl('');
    }
  }, [currentInstitution.iconSrc, fetchImage]);

  return (
    <Grid container>
      <Grid item xs={12} sm={4} md={3} order={{ xs: 2, sm: 1 }}>
        <CourseOutlineInstitute
          image={logoUrl || '/ascd_avatar.png'}
          title={currentInstitution.name || ''}
          sx={{
            pl: 1,
            pt: 1,
            ml: 1,
            maxWidth: '20%',
            minWidth: { xs: '80px', sm: '100px' },
            minHeight: { xs: '80px', sm: '100px' },
            '& img': {
              maxWidth: { xs: '80px', sm: '100px' },
              maxHeight: { xs: '80px', sm: '100px' },
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={9} order={{ xs: 1, sm: 2 }}>
        <CourseOutlineHeader
          sx={{
            '& div': {
              opacity: 0.9,
            },
          }}
          customRender={
            <>
              {titleIcon && (
                <SvgIcon
                  fill="none"
                  icon={titleIcon}
                  viewBox={titleViewBox || '0 0 45 44'}
                  sx={
                    {
                      pr: 1,
                      fontSize: theme1.font.size.H4,
                      ...iconSx,
                    } as unknown as undefined
                  }
                />
              )}
              <Typography
                data-testid="admin-header"
                tag="h1"
                text={title}
                sx={(theme) => ({
                  color: theme.palette.white[100],
                  fontWeight: theme.font.weight.SEMI_BOLD,
                  fontSize: { xs: theme.font.size.LARGE, sm: theme.font.size.H4 },
                })}
              />
            </>
          }
        />
      </Grid>
    </Grid>
  );
};

export default PagesSubHeader;

import { useCallback, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Badge } from '@mui/material';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import first from 'lodash/first';
import GET_IMAGE_URL from '@graphql/schema/getImageUrl.graphql';

interface IIcon extends AvatarProps {
  isOnline?: boolean;
  showOnlineStatus?: boolean;
}

interface IIconWrapperWrapper extends IIcon {
  isOnline?: boolean;
  showOnlineStatus?: boolean;
}

const Icon = ({ alt, ...restProps }: IIconWrapperWrapper) => (
  <Avatar alt={`Profile picture of  ${alt}`} {...restProps}>
    {first(alt)}
  </Avatar>
);

const checkIfAvatarIsUrl = (value: string) => value.startsWith('http') || value.startsWith('https');

const IconWrapper = ({
  isOnline = false,
  showOnlineStatus = false,
  ...restProps
}: IIcon): JSX.Element => {
  const apolloClient = useApolloClient();
  const [imageSrc, setImageSrc] = useState('');

  const fetchImage = useCallback(
    async (imageName: string) => {
      const MINUTE_MS = 60000;
      const defaultCacheImages = localStorage.getItem('images');
      let localStorageImages = defaultCacheImages ? JSON.parse(defaultCacheImages) : {};
      if (localStorageImages[`${imageName}`]) return localStorageImages[`${imageName}`].image;

      const response = await apolloClient.query({
        query: GET_IMAGE_URL,
        variables: { imageName },
        fetchPolicy: 'network-only',
      });

      const image = response?.data?.getImageUrl?.url || '';
      const currentTime = new Date();
      localStorageImages = {
        ...localStorageImages,
        [imageName]: {
          image,
          expiry: currentTime.getTime() + MINUTE_MS * 14,
        },
      };

      localStorage.setItem('images', JSON.stringify(localStorageImages));
      return image;
    },
    [apolloClient],
  );

  useEffect(() => {
    if (restProps?.src) {
      const url = restProps.src;
      if (!checkIfAvatarIsUrl(url)) {
        fetchImage(url).then((u) => setImageSrc(u));
      }
    }
  }, [fetchImage, restProps.src]);

  if (showOnlineStatus)
    return (
      <Badge
        variant="dot"
        overlap="circular"
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: isOnline ? '#44b700' : 'red',
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Icon
          {...restProps}
          {...(imageSrc && {
            src: imageSrc,
          })}
        />
      </Badge>
    );

  return (
    <Icon
      {...restProps}
      {...(imageSrc && {
        src: imageSrc,
      })}
    />
  );
};

export default IconWrapper;

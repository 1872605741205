import { useEffect, useState } from 'react';

const useVersionCheck = () => {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);

  useEffect(() => {
    const checkVersion = async () => {
      try {
        if (process.env.NODE_ENV !== 'development' && process.env.APP_ENV === 'DEV') {
          const response = await fetch('/api/build-id');
          const { buildId } = await response.json();
          if (buildId !== process.env.BUILD_ID) {
            setNewVersionAvailable(true);
          }
        }
      } catch (error) {
        console.error('Failed to fetch build ID:', error);
      }
    };

    const interval = setInterval(checkVersion, 60000); // Check every 60 seconds

    return () => clearInterval(interval);
  }, []);

  return newVersionAvailable;
};

export default useVersionCheck;

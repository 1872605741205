import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { IConversation } from '@ascd/witsby-components';
import { Box } from '@mui/material';
import get from 'lodash/get';
import { AppContext } from '@contexts';
import GET_CONVERSATIONS_FROM_USER_ID from '@graphql/schema/getConversationsFromUserId.graphql';
import { commonConversationsData, commonConversationsFilter } from '@utils/chat';
import { Conversations } from '../Conversations';

const ConversationList = (): JSX.Element => {
  const {
    state: { currentUser },
  } = useContext(AppContext);

  const { loading: groupsLoading, data: groupsData } = useQuery(GET_CONVERSATIONS_FROM_USER_ID, {
    ...commonConversationsFilter(currentUser.oktaId, 'GROUP'),
  });

  const { loading: assignmentsLoading, data: assignmentsData } = useQuery(
    GET_CONVERSATIONS_FROM_USER_ID,
    { ...commonConversationsFilter(currentUser.oktaId, 'ASSIGNMENT') },
  );

  const { loading: directMessagesLoading, data: directMessagesData } = useQuery(
    GET_CONVERSATIONS_FROM_USER_ID,
    { ...commonConversationsFilter(currentUser.oktaId, 'DIRECT') },
  );

  const groupsConversations = get(
    groupsData,
    'getConversationsFromUserId',
    commonConversationsData,
  );
  const assignmentsConversations = get(
    assignmentsData,
    'getConversationsFromUserId',
    commonConversationsData,
  );
  const directMessagesConversations = get(
    directMessagesData,
    'getConversationsFromUserId',
    commonConversationsData,
  );

  const isArchiveLoading = groupsLoading || assignmentsLoading || directMessagesLoading;

  const getArchiveData = () => {
    if (isArchiveLoading) return commonConversationsData;
    return {
      conversations: [
        ...groupsConversations.conversations,
        ...assignmentsConversations.conversations,
      ],
      totalCount: groupsConversations.totalCount + assignmentsConversations.totalCount,
      totalUnreadCount:
        groupsConversations.totalUnreadCount + assignmentsConversations.totalUnreadCount,
      unreadCountByConversations: {
        ...groupsConversations.unreadCountByConversations,
        ...assignmentsConversations.unreadCountByConversations,
      },
    };
  };

  const getDirectMessagesData = (isBlocked = false) => {
    if (directMessagesLoading) return commonConversationsData;

    const blockedUsers: string[] = get(currentUser, 'preferences.chat.blockedUsers', []);
    const blockedByUsers: string[] = get(currentUser, 'preferences.chat.blockedByUsers', []);
    return {
      ...directMessagesConversations,
      conversations: directMessagesConversations.conversations.filter((c: IConversation) => {
        const user = c.participants.find((p) => p.userInfo.id !== currentUser.oktaId);
        const checkIfBlocked =
          blockedUsers?.includes(user?.userInfo.id as string) ||
          blockedByUsers?.includes(user?.userInfo.id as string);
        if (isBlocked) return checkIfBlocked;
        return !checkIfBlocked;
      }),
    };
  };

  return (
    <Box sx={{ mt: 1.5 }}>
      <Conversations
        title="Group"
        isGroupMessage
        loading={groupsLoading}
        data={groupsConversations}
        data-testid="group-conversation"
      />
      <Conversations
        title="Assignment"
        isAssignmentMessage
        loading={assignmentsLoading}
        data={assignmentsConversations}
        data-testid="assignment-conversation"
      />
      <Conversations
        isDirectMessage
        title="Direct Message"
        data={getDirectMessagesData()}
        loading={directMessagesLoading}
        data-testid="direct-conversation"
      />
      <Conversations
        isBlocked
        title="Blocked"
        loading={directMessagesLoading}
        data-testid="blocked-conversation"
        data={getDirectMessagesData(true)}
      />
      <Conversations
        isArchived
        title="Archived"
        data={getArchiveData()}
        loading={isArchiveLoading}
        data-testid="archived-conversation"
      />
    </Box>
  );
};

export default ConversationList;

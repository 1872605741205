import {
  THEME,
  ICourseStructure,
  IAssessmentItems,
  convertMinsToSeconds,
  IInstitutionSettings,
  IUserLearningItemCollection,
  ICollectionLearningItemsMappings,
} from '@ascd/witsby-components';
import { createTheme, ThemeOptions } from '@mui/material';
import { startCase, toLower, groupBy, omit, sum, map, get, unescape, capitalize } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { CARD_BUTTON_NAME, STRUCTURE_TYPE } from '@constants';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

const WPM = 175; // Word Per Minute

export const getQuillContent = (content = '') =>
  unescape(content.replaceAll('&nbsp;', ' ').replaceAll('“', '"').replaceAll('”', '"'));

export function readingTime(text: string): number {
  const words = getQuillContent(text).trim().split(/\s+/).length;
  const expectedTime = Math.ceil(words / WPM);
  return convertMinsToSeconds(expectedTime);
}

export const titleCase = (value: string) => {
  if (!value) return '';
  return startCase(toLower(value));
};

export const createMarkup = (content = '') => ({ __html: getQuillContent(content) });

export const getSections = (
  structure: ICourseStructure[],
  collectionLearningItemsMappings: ICollectionLearningItemsMappings[],
) => {
  if (!structure || !structure.length) return [];

  const collectionLearningItemsMappingsId = collectionLearningItemsMappings.map(
    (c: ICollectionLearningItemsMappings) => c?.parentId,
  );

  const chapters = structure
    .filter((s) => s.type === 'CHAPTER')
    .map((s) => {
      if (collectionLearningItemsMappingsId.includes(s.id)) {
        return {
          ...s,
          itemsCount: collectionLearningItemsMappings.filter(
            (c: ICollectionLearningItemsMappings) => c?.parentId === s.id,
          ).length,
        };
      }
      return s;
    });

  const groupByChapters = groupBy(chapters, (item) => item.parentId);
  const sections = structure
    .filter((s) => s.type === 'SECTION')
    .map((s) => ({ ...s, chapters: groupByChapters[s.id] }));
  return sections;
};

export const getCourseTheme = (
  courseTheme: { primaryColor?: string; secondaryColor?: string } = {},
) => {
  if (!courseTheme?.primaryColor && !courseTheme?.secondaryColor) {
    return createTheme(THEME as ThemeOptions);
  }

  const colors: { main?: string; light?: string } = {};
  if (courseTheme?.primaryColor) {
    colors.main = courseTheme.primaryColor;
  }
  if (courseTheme?.secondaryColor) {
    colors.light = courseTheme.secondaryColor;
  }

  return createTheme({
    ...THEME,
    palette: {
      ...THEME.palette,
      primary: {
        ...THEME.palette.primary,
        ...colors,
      },
    },
  } as ThemeOptions);
};

export const getChapters = (
  structures: ICourseStructure[],
  currentChapterId?: string,
): {
  chapters: ICourseStructure[];
  nextChapter?: ICourseStructure;
  firstChapter?: ICourseStructure;
  previousChapter?: ICourseStructure;
} => {
  if (!structures?.length) return { chapters: [], firstChapter: {} as ICourseStructure };

  const sections = structures.filter((structure) => structure.type === STRUCTURE_TYPE.SECTION);
  const chapters = structures.filter((structure) => structure.type === STRUCTURE_TYPE.CHAPTER);
  const chaptersGroupByParentId = groupBy(chapters, (item) => item.parentId);
  chapters.length = 0;

  sections.forEach((section) => {
    if (chaptersGroupByParentId[section.id]?.length) {
      chaptersGroupByParentId[section.id].forEach((structure) => {
        chapters.push(structure);
      });
    }
  });

  let nextChapter;
  let firstChapter;
  let previousChapter;
  if (chapters.length) {
    firstChapter = chapters[0];

    if (currentChapterId) {
      const index = chapters.findIndex((chapter) => chapter.id === currentChapterId);
      if (index > -1 && chapters[index + 1]) {
        nextChapter = chapters[index + 1];
      }
      if (index > -1 && chapters[index - 1]) {
        previousChapter = chapters[index - 1];
      }
    }
  }
  return {
    chapters,
    nextChapter,
    firstChapter,
    previousChapter,
  };
};
export const getDefaultSettings = (
  settings: IInstitutionSettings | undefined,
  isInstitution = false,
) => {
  const defaultSettings = {
    sideBar: 'COLLAPSED',
    completionFactor: {
      assessments: [],
      percentage: '100',
    },
    navigationFlow: 'FREE_FLOW',
    theme: { fontFamily: '', fontSize: '', primaryColor: '' },
  };
  return omit(
    {
      ...defaultSettings,
      ...(settings || {}),
      completionFactor: {
        ...omit(settings?.completionFactor || defaultSettings.completionFactor, ['__typename']),
      },
    },
    isInstitution
      ? ['__typename']
      : ['labels', '__typename', 'homeBannerImage', 'chatAndCommunication'],
  );
};

export const getBannerText = (homeBannerImageData: IInstitutionSettings['homeBannerImage']) => {
  const defaultText = 'WELCOME TO WITSBY';
  if (!homeBannerImageData?.text) return defaultText;

  const { startDate, endDate } = homeBannerImageData;

  if (!startDate && !endDate) return defaultText;
  const currentDate = DateTime.fromJSDate(new Date()).startOf('day');
  const endDateTime = DateTime.fromJSDate(new Date(endDate as string)).startOf('day');
  const startDateTime = DateTime.fromJSDate(new Date(startDate as string)).startOf('day');

  if (startDate && endDate) {
    if (currentDate >= startDateTime && currentDate <= endDateTime) return homeBannerImageData.text;
    return defaultText;
  }

  if (startDate) {
    if (currentDate >= startDateTime) return homeBannerImageData.text;
    return defaultText;
  }

  if (endDate && currentDate <= endDateTime) return homeBannerImageData.text;

  return defaultText;
};

export const getBannerAnimation = (
  homeBannerImageData: IInstitutionSettings['homeBannerImage'],
) => {
  const defaultValue = false;
  if (!homeBannerImageData) return defaultValue;

  const { animationStartDate, animationEndDate } = homeBannerImageData;

  if (!animationStartDate && !animationEndDate) return defaultValue;
  const currentDate = DateTime.fromJSDate(new Date()).startOf('day');
  const animationEndDateTime = DateTime.fromJSDate(new Date(animationEndDate as string)).startOf(
    'day',
  );
  const animationStartDateTime = DateTime.fromJSDate(
    new Date(animationStartDate as string),
  ).startOf('day');

  if (animationStartDate && animationEndDate) {
    if (currentDate >= animationStartDateTime && currentDate <= animationEndDateTime) return true;
    return defaultValue;
  }

  if (animationStartDate) {
    if (currentDate >= animationStartDateTime) return true;
    return defaultValue;
  }

  if (animationEndDate && currentDate <= animationEndDateTime) return true;

  return defaultValue;
};

export const getBannerCoverImage = (
  homeBannerImageData: IInstitutionSettings['homeBannerImage'],
  image: string,
) => {
  const defaultValue = '';
  if (!homeBannerImageData) return defaultValue;

  const { startDate, endDate } = homeBannerImageData;

  if (!startDate && !endDate) return defaultValue;
  const currentDate = DateTime.fromJSDate(new Date()).startOf('day');
  const endDateTime = DateTime.fromJSDate(new Date(endDate as string)).startOf('day');
  const startDateTime = DateTime.fromJSDate(new Date(startDate as string)).startOf('day');

  if (startDate && endDate) {
    if (currentDate >= startDateTime && currentDate <= endDateTime) return image;
    return defaultValue;
  }

  if (startDate) {
    if (currentDate >= startDateTime) return image;
    return defaultValue;
  }

  if (endDate && currentDate <= endDateTime) return image;

  return defaultValue;
};

export const getLibraryButtonTitle = (
  userLearningItemCollection: IUserLearningItemCollection | undefined,
) => {
  if (userLearningItemCollection && userLearningItemCollection?.userCollectionStatus) {
    if (
      capitalize(userLearningItemCollection.userCollectionStatus) === 'Enroll' ||
      capitalize(userLearningItemCollection.userCollectionStatus) === CARD_BUTTON_NAME.ADD
    ) {
      return CARD_BUTTON_NAME.START;
    }
    if (capitalize(userLearningItemCollection.userCollectionStatus) === 'Start') {
      return CARD_BUTTON_NAME.RESUME;
    }
    return capitalize(userLearningItemCollection.userCollectionStatus);
  }

  return CARD_BUTTON_NAME.START;
};

export function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export const getAssessmentClockHours = (defaultDuration: number): string => {
  const {
    hours = 0,
    minutes = 0,
    seconds = 0,
  } = Duration.fromMillis(defaultDuration * 1000)
    .shiftTo('hours', 'minutes', 'seconds')
    .toObject();

  const formatDuration = () => {
    if (!hours && !minutes && !seconds) return '00:00';

    if (hours && minutes) return `${hours}:${minutes}`;

    if (hours) {
      if (minutes) return `${hours}:${minutes}`;
      return `${hours}:00`;
    }

    if (minutes) {
      if (seconds) return `${minutes}:${seconds}`;
      return `${minutes}:00`;
    }
    if (seconds) return `00:${seconds}`;
    return '00:00';
  };

  const duration = formatDuration();

  return `${duration}`;
};

export const getAssessmentPoints = (items: IAssessmentItems[]): number =>
  sum(map(items, (item) => get(item, 'questions[0].data.validation.valid_response.score', 0)));

export const base64UrlToImageFile = (base64Url: string, fileName: string): File => {
  const base64 = base64Url.replace(/^data:image\/[a-z]+;base64,/, '');
  const base64String = base64.replace(/-/g, '+').replace(/_/g, '/');
  const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));
  const blob = new Blob([bytes], { type: 'image/jpeg' }); // Adjust the MIME type accordingly

  return new File([blob], fileName, { type: blob.type });
};

export const convertUrlToBase64 = async (url: string) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    )
    .catch(() => {
      //
    });

export const getCourseTypeForBreadCrumb = (courseType: string) => {
  if (courseType === 'strategy') return 'strategies';
  return `${courseType}s`;
};

export const datePickerFormatDate = (date?: string | null): string | undefined => {
  if (date) {
    const formattedDate = DateTime.fromISO(date, { setZone: true }).toUTC().startOf('day').toISO();
    if (formattedDate) {
      return formattedDate;
    }
  }
  return undefined;
};

export const isDateMoreThanXDaysOld = (dateString: string, days: number) => {
  const inputDate = DateTime.fromISO(dateString, { setZone: true }).toUTC().startOf('day');
  const targetDate = DateTime.now().toUTC().startOf('day').minus({ days });
  return inputDate < targetDate;
};

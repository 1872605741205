import { IOktaUser, ICurrentUser, ISalesforceUser } from '@ascd/witsby-components';
import isEmpty from 'lodash/isEmpty';
import { SALESFORCE_USER_ROLES, USER_ROLES } from '@constants';
import { IUserRole } from '@types';

export const getUserRole = (roles: string[]): IUserRole => {
  const isAscdAdmin =
    roles?.includes(USER_ROLES.ASCD_ADMIN) ||
    roles?.includes(SALESFORCE_USER_ROLES.ASCD_ADMIN) ||
    roles?.includes(SALESFORCE_USER_ROLES.ASCD);
  const isInstitutionalAdmin =
    roles?.includes(USER_ROLES.INSTITUTIONAL_ADMIN) ||
    roles?.includes(SALESFORCE_USER_ROLES.INSTITUTIONAL_ADMIN);
  // || roles?.includes(SALESFORCE_USER_ROLES.ADMIN);
  const isManager =
    roles?.includes(USER_ROLES.MANAGER) || roles?.includes(SALESFORCE_USER_ROLES.MANAGER);
  const isAuthor =
    roles?.includes(USER_ROLES.AUTHOR) || roles?.includes(SALESFORCE_USER_ROLES.AUTHOR);
  const isLearner =
    roles?.includes(USER_ROLES.LEARNER) ||
    roles?.includes(SALESFORCE_USER_ROLES.LEARNER) ||
    isEmpty(roles);
  return { isManager, isAuthor, isLearner, isInstitutionalAdmin, isAscdAdmin };
};

export const getOktaUserName = (currentUser: IOktaUser): string => {
  if (!currentUser.profile) return '';
  const {
    profile: { displayName, givenName, familyName = '' },
  } = currentUser;
  if (displayName) return displayName;
  if (givenName && familyName) return `${givenName} ${familyName}`;
  if (givenName) return givenName;
  return familyName;
};

export const getUserName = (currentUser: ICurrentUser | ISalesforceUser): string => {
  if (!currentUser.firstName && !currentUser.lastName) return '';
  const { firstName, lastName } = currentUser;
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;
  return lastName;
};

import { useState, useEffect } from 'react';
import { Dialog } from '@ascd/witsby-components';

interface NewVersionNotificationProps {
  newVersionAvailable: boolean;
}

const NewVersionNotification = ({ newVersionAvailable }: NewVersionNotificationProps) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (newVersionAvailable) {
      setVisible(true);
    }
  }, [newVersionAvailable]);

  if (!visible || process.env.NODE_ENV === 'development') return null;

  const dialogProps = {
    open: true,
    maxHeight: 400,
    btnTitle: 'Reload',
    content:
      "A new version of the witsby is available. Please refresh the page to ensure you're using latest version",
    handleClose: () => null,
    title: 'New App Version Available!',
    onClick: () => window.location.reload(),
  };

  return (
    <div>
      <Dialog {...dialogProps} />
    </div>
  );
};

export default NewVersionNotification;

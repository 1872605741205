import {
  ICourse,
  ICitations,
  IAuthorInfo,
  IInstitution,
  ICourseSettings,
  ILastUpdatedInfo,
  IHandleCourseUpdate,
  IInstitutionSettings,
  ICollectionLearningItem,
  IHandleCreateLearningItem,
} from '@ascd/witsby-components';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';

export const removeAuthorTypename = (author: IAuthorInfo) => {
  const cloneAuthor = cloneDeep(omit(author, '__typename'));
  if (cloneAuthor?.organization) {
    cloneAuthor.organization = omit(cloneAuthor.organization, '__typename');
  }
  return cloneAuthor;
};

export const removeLastUpdatedInfoTypename = (lastUpdatedInfo: ILastUpdatedInfo) => {
  const cloneLastUpdatedInfo = cloneDeep(omit(lastUpdatedInfo, '__typename'));
  if (cloneLastUpdatedInfo?.author) {
    cloneLastUpdatedInfo.author = removeAuthorTypename(cloneLastUpdatedInfo.author);
  }
  return cloneLastUpdatedInfo;
};

export const removeCitationTypename = (citationData: ICitations) => {
  const cloneCitationData = cloneDeep(omit(citationData, '__typename'));

  if (cloneCitationData?.author) {
    cloneCitationData.author = cloneCitationData.author.map((author) =>
      cloneDeep(omit(author, '__typename')),
    );
  }

  if (cloneCitationData?.books) {
    cloneCitationData.books = cloneCitationData.books.map((books) =>
      cloneDeep(omit(books, '__typename')),
    );
  }

  return cloneCitationData;
};

export const removeLearningItemTypename = (
  learningItemData: ICollectionLearningItem | IHandleCreateLearningItem,
) => {
  const cloneLearningItemData = cloneDeep(omit(learningItemData, ['__typename', 'createdAt']));

  if (cloneLearningItemData?.items?.length) {
    cloneLearningItemData.items = cloneLearningItemData.items.map((item) =>
      cloneDeep(omit(item, '__typename')),
    );
  }

  if (cloneLearningItemData?.author) {
    cloneLearningItemData.author = removeAuthorTypename(cloneLearningItemData.author);
  }

  if (cloneLearningItemData?.citations) {
    cloneLearningItemData.citations = removeCitationTypename(cloneLearningItemData.citations);
  }

  if (cloneLearningItemData?.lastUpdatedInfo) {
    cloneLearningItemData.lastUpdatedInfo = removeLastUpdatedInfoTypename(
      cloneLearningItemData.lastUpdatedInfo as ILastUpdatedInfo,
    );
  }

  return cloneLearningItemData;
};

export const removeCourseTypename = (courseData: ICourse | IHandleCourseUpdate) => {
  const cloneCourseData = cloneDeep(courseData) as IHandleCourseUpdate;

  if (cloneCourseData?.structure) {
    cloneCourseData.structure = cloneCourseData.structure.map((structure) =>
      cloneDeep(omit(structure, '__typename')),
    );
  }

  return cloneCourseData;
};

export const removeSettingsTypename = (
  settings: IInstitutionSettings | ICourseSettings,
  isInstitution = true,
) => {
  const cloneSettings = cloneDeep(omit(settings, ['__typename', 'createdAt']));
  if (cloneSettings?.completionFactor) {
    cloneSettings.completionFactor = omit(cloneSettings?.completionFactor, '__typename') || {
      assessments: [],
      percentage: '100',
    };
  }
  if ((cloneSettings as IInstitutionSettings)?.labels?.length && isInstitution) {
    (cloneSettings as IInstitutionSettings).labels = (
      cloneSettings as IInstitutionSettings
    ).labels.map((label) => omit(label, ['__typename', 'createdAt']));
  }
  return cloneSettings as IInstitutionSettings;
};

export const removeInstitutionTypename = (institution: IInstitution) => {
  const cloneInstitution = cloneDeep(omit(institution, ['__typename', 'createdAt']));

  if (cloneInstitution?.hierarchies?.length) {
    cloneInstitution.hierarchies = cloneInstitution.hierarchies.map((hierarchy) => {
      const cloneHierarchy = cloneDeep(omit(hierarchy, ['__typename', 'createdAt']));
      if (cloneHierarchy?.author) {
        cloneHierarchy.author = removeAuthorTypename(cloneHierarchy.author);
      }
      if (cloneHierarchy?.lastUpdatedInfo) {
        cloneHierarchy.lastUpdatedInfo = removeLastUpdatedInfoTypename(
          cloneHierarchy.lastUpdatedInfo,
        );
      }
      return cloneHierarchy;
    });
  }

  if (cloneInstitution?.contacts?.length) {
    cloneInstitution.contacts = cloneInstitution.contacts.map((contact) =>
      omit(contact, ['__typename']),
    );
  }

  if (cloneInstitution?.structure?.length) {
    cloneInstitution.structure = cloneInstitution.structure.map((structure) =>
      omit(structure, ['__typename', 'createdAt', 'updatedAt']),
    );
  }

  if (cloneInstitution?.settings) {
    cloneInstitution.settings = removeSettingsTypename(cloneInstitution.settings);
  }

  if (cloneInstitution?.lastUpdatedInfo) {
    cloneInstitution.lastUpdatedInfo = removeLastUpdatedInfoTypename(
      cloneInstitution.lastUpdatedInfo,
    );
  }

  return cloneInstitution;
};

import { IInstitution, ICurrentUser } from '@ascd/witsby-components';
import AES from 'crypto-js/aes';
import { getUserName } from './authUser';

export const getUserInfo = (user: ICurrentUser, institution: IInstitution, isEncrypted = false) => {
  const userInfo = {
    id: user.oktaId,
    name: getUserName(user),
    email: user.email,
    avatarUrl: user.avatarUrl,
    schoolId: user.schoolId,
    districtId: user.districtId,
    witsbyContractGroupId: user.witsbyContractGroupId,
    organization: {
      id: institution?._id || '',
      name: institution?.name || '',
    },
  };

  if (isEncrypted)
    return AES.encrypt(JSON.stringify(userInfo), process.env.CRYPTO_ENCRYPTED_KEY || '').toString();
  return userInfo;
};

import { IncomingMessage, ServerResponse } from 'http';
import { useMemo } from 'react';
import {
  HttpLink,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import omitDeep from 'omit-deep-lodash';
// import { handleGraphqlError } from '@utils';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

export type ResolverContext = {
  req?: IncomingMessage;
  res?: ServerResponse;
};

const errorLink = onError((errors) => {
  const { graphQLErrors, networkError, operation } = errors;

  if (graphQLErrors) {
    if (graphQLErrors?.some((v) => v?.extensions?.code === 'UNAUTHENTICATED')) {
      window.location.href = '/';
    }
    const def = getMainDefinition(operation.query);
    if (def && def?.operation === 'query') {
      // handleGraphqlError(errors);
    }
    graphQLErrors.map((graphqlError) => graphqlError);
  }
  if (networkError) {
    console.error('networkError', networkError);
  }
  // prepared for logging
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {},
    },
  },
});

const contentfulCache = new InMemoryCache({});

function createApolloClient() {
  const authLink = setContext((_, { headers }) => {
    const {
      accessToken: { accessToken },
    } = JSON.parse(localStorage.getItem('okta-token-storage') || '');

    const userinfo = localStorage.getItem('header-info') || '';

    return {
      headers: {
        ...headers,
        userinfo,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  });

  const uri = process.env.SERVER_URL;

  const httpLink = new HttpLink({
    uri,
    credentials: 'same-origin',
  });

  const cleanTypenameLink = new ApolloLink((operation, forward) => {
    const def = getMainDefinition(operation.query);
    if (def && def.operation === 'mutation') {
      // eslint-disable-next-line no-param-reassign
      operation.variables = omitDeep(operation.variables, ['__typename']);
    }
    return forward ? forward(operation) : null;
  });

  return new ApolloClient({
    cache,
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([cleanTypenameLink, errorLink, authLink.concat(httpLink)]),
  });
}

export function initializeApollo(
  initialState: NormalizedCacheObject | null,
): ApolloClient<NormalizedCacheObject> {
  const apolloClientInitialized = apolloClient ?? createApolloClient();

  if (initialState) {
    const existingCache = apolloClientInitialized.extract();

    apolloClientInitialized.cache.restore({
      ...existingCache,
      ...initialState,
    });
  }

  if (typeof window === 'undefined') return apolloClientInitialized;

  if (!apolloClient) apolloClient = apolloClientInitialized;

  return apolloClientInitialized;
}

export function useApollo(
  initialState: NormalizedCacheObject | null,
): ApolloClient<NormalizedCacheObject> {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}

export const contentfulDirectClient = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      }
      if (networkError) console.error(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/environments/${process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT}`,
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${
          process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW
            ? process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN
            : process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN
        }`,
      },
    }),
  ]),
  cache: contentfulCache,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
  },
});

import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { IConversation, ICurrentUser, Loader } from '@ascd/witsby-components';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box } from '@mui/material';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { AppContext } from '@contexts';
import { ChatContext, eChatActionType } from '@contexts/chatContext';
import USER_DETAILS from '@graphql/schema/userDetails.graphql';
import { handleGraphqlError } from '@utils';
import { ChatLayout } from '../ChatLayout';
import { Participants } from '../Participants';

const ChooseParticipant = (): JSX.Element => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const {
    state: { detailsPage },
    dispatch: chatDispatch,
  } = useContext(ChatContext);

  const conversation: IConversation = get(
    detailsPage,
    'detailsPageData.conversation',
    {},
  ) as IConversation;

  const { loading, data } = useQuery(USER_DETAILS, {
    variables: {
      filter: {
        page: 0,
        limit: 10000,
        sortDirection: 'DESC',
        sortField: 'updatedAt',
        searchField: ['witsbyContractGroupId'],
        searchText: currentUser?.witsbyContractGroupId,
      },
    },
    fetchPolicy: 'network-only',
    onError: handleGraphqlError,
  });

  const participants: ICurrentUser[] = get(data, 'userDetails.userDetails', []);

  return (
    <ChatLayout
      title="Choose DM Participant"
      {...(!isEmpty(conversation) && {
        headerChildren: (
          <CloseOutlinedIcon
            tabIndex={0}
            onClick={() => {
              chatDispatch({
                data: {
                  type: 'CHAT',
                  detailsPageData: { conversation },
                },
                type: 'DETAILS_PAGE' as eChatActionType.DETAILS_PAGE,
              });
            }}
            sx={(theme) => ({
              cursor: 'pointer',
              width: theme.size.LARGE,
              height: theme.size.LARGE,
              color: theme.palette.grey[700],
              fontSize: theme.font.size.REGULAR,
              background: theme.palette.white[100],
              borderRadius: theme.border.radius.FULL,
              '&:focus': {
                outlineColor: theme.palette.primary.main,
              },
            })}
          />
        ),
      })}>
      <Box sx={{ height: '100%', p: { xs: 0, md: 2 } }}>
        {loading && <Loader isFullScreen />}
        {!loading && (
          <Participants
            showBlockUser
            participants={participants?.map((participant) => ({
              id: participant.oktaId,
              name: participant.name,
              email: participant.email,
              schoolGrades: participant.grades,
              schoolId: participant.schoolId || '',
              avatarUrl: participant.avatarUrl || '',
              districtId: participant.districtId || '',
              witsbyContractGroupId: participant.witsbyContractGroupId || '',
              organization: {
                id: participant.organization.id,
                name: participant.organization.name,
              },
            }))}
          />
        )}
      </Box>
    </ChatLayout>
  );
};

export default ChooseParticipant;

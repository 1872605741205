import { BoxProps, Box } from '@mui/material';

export interface IDisabledSendMessageProps extends BoxProps {
  text?: string;
}

const DisabledSendMessage = ({
  text = 'Only admins can send messages',
}: IDisabledSendMessageProps): JSX.Element => (
  <Box
    sx={(theme) => ({
      py: 3,
      textAlign: 'center',
      color: theme.palette.grey[700],
      fontSize: theme.font.size.SMALL,
      borderRadius: theme.border.radius.SMALL,
      backgroundColor: theme.palette.grey[500],
    })}>
    {text}
  </Box>
);

export default DisabledSendMessage;

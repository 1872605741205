import { useContext } from 'react';
import { Box } from '@mui/material';
import { AppContext, SocketContext } from '@contexts';
import { getIfParticipantsTyping } from '@utils';

interface IParticipantsTyping {
  conversationId: string;
}

const ParticipantsTyping = ({ conversationId }: IParticipantsTyping) => {
  const { typingUsers } = useContext(SocketContext);
  const {
    state: { currentUser },
  } = useContext(AppContext);

  const { isTyping, users } = getIfParticipantsTyping(
    typingUsers,
    conversationId,
    currentUser.oktaId,
  );

  if (!isTyping) return null;

  const getTypingMessage = () => {
    const userCount = users.length;
    if (userCount === 1) return `${users[0].name} is typing`;
    if (userCount <= 2)
      return `${users
        .slice(0, userCount - 1)
        .map((user) => user.name)
        .join(', ')} and ${users[userCount - 1].name} are typing`;
    return 'Several people are typing';
    // return `${users
    //   .slice(0, 4)
    //   .map((user) => user.name)
    //   .join(', ')} and ${userCount - 4} others are typing`;
  };

  return (
    <Box
      sx={(theme) => ({
        ml: 2,
        fontStyle: 'italic',
        display: 'inline-block',
        color: theme.palette.grey[100],
        fontSize: theme.font.size.X_SMALL,
        '.dots::after': {
          pl: 0.2,
          content: '""',
          animation: 'dots 1.5s steps(3, end) infinite',
        },
        '@keyframes dots': {
          '0%': { content: '""' },
          '33%': { content: '"."' },
          '66%': { content: '".."' },
          '100%': { content: '"..."' },
        },
      })}>
      {getTypingMessage()}
      <span className="dots" />
    </Box>
  );
};

export default ParticipantsTyping;

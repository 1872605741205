import { ReactNode, useReducer, createContext, Dispatch, Reducer, FC, useMemo } from 'react';
import { IConversation } from '@ascd/witsby-components';

export enum eChatActionType {
  RESET = 'RESET',
  DETAILS_PAGE = 'DETAILS_PAGE',
  GLOBAL_CHAT_SEARCH = 'GLOBAL_CHAT_SEARCH',
  DRAFT_MESSAGES = 'DRAFT_MESSAGES',
  ATTACHMENT_IS_UPLOADING = 'ATTACHMENT_IS_UPLOADING',
}

export interface IDetailsPage {
  type?: 'CHAT_SETTINGS' | 'CHAT' | 'CHAT_DETAILS' | 'CHOOSE_PARTICIPANT';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detailsPageData?: any;
}

export type ChatContextData = string | IConversation | IDetailsPage | null;

interface IMessageAttachment extends File {
  preview?: string;
}
interface IMessageType {
  message?: string;
  attachment?: IMessageAttachment;
}
interface IDraftMessages {
  [conversationId: string]: IMessageType;
}

interface IAttachmentIsUpLoading {
  [conversationId: string]: boolean;
}

interface ChatContextState {
  globalChatSearch?: string;
  detailsPage: IDetailsPage | null;
  draftMessages: IDraftMessages;
  attachmentIsUploading: IAttachmentIsUpLoading;
}

interface ChatContextAction {
  type: eChatActionType;
  data: ChatContextData;
}

const initialState: ChatContextState = {
  detailsPage: null,
  draftMessages: {},
  attachmentIsUploading: {},
};

const reducer = (state: ChatContextState, action: ChatContextAction) => {
  const { type, data } = action;

  switch (type) {
    case eChatActionType.GLOBAL_CHAT_SEARCH:
      return { ...state, globalChatSearch: data as string };
    case eChatActionType.DETAILS_PAGE:
      return { ...state, detailsPage: data as IDetailsPage };
    case eChatActionType.DRAFT_MESSAGES:
      return {
        ...state,
        draftMessages: data as IDraftMessages,
      };
    case eChatActionType.ATTACHMENT_IS_UPLOADING:
      return {
        ...state,
        attachmentIsUploading: data as IAttachmentIsUpLoading,
      };
    case eChatActionType.RESET:
      return initialState;
    default:
      return state;
  }
};

const ChatContext = createContext<{
  state: ChatContextState;
  dispatch: Dispatch<ChatContextAction>;
}>({
  state: initialState,
  dispatch: () => {
    //
  },
});

const ChatContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<ChatContextState, ChatContextAction>>(
    reducer,
    initialState,
  );
  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

const ChatContextConsumer = ChatContext.Consumer;

export { ChatContext, ChatContextProvider, ChatContextConsumer };

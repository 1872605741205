import { useRouter } from 'next/router';
import { BoxProps, Box } from '@mui/material';

export interface INoConversationFoundProps extends BoxProps {
  text?: string;
  isDisabled?: boolean;
}

const NoConversationFound = ({
  isDisabled = false,
  text = 'No archived chats available',
}: INoConversationFoundProps): JSX.Element => {
  const router = useRouter();
  const isPopUpChat = router.pathname !== '/chat-and-communication';

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.grey[200],
        fontSize: theme.font.size.SMALL,
        fontWeight: theme.font.weight.REGULAR,
        ...(isDisabled && {
          my: 2,
        }),
        ...(!isDisabled && {
          my: 1,
          ...(!isPopUpChat && {
            minHeight: 100,
          }),
        }),
      })}>
      {text}
    </Box>
  );
};

export default NoConversationFound;

import { ReactNode, useReducer, createContext, Dispatch, Reducer, FC, useMemo } from 'react';
import { IInstitution, ICurrentUser } from '@ascd/witsby-components';
import { IUserRole } from '@types';

export enum eActionType {
  RESET = 'RESET',
  CURRENT_USER = 'CURRENT_USER',
  CURRENT_USER_ROLE = 'CURRENT_USER_ROLE',
  CURRENT_INSTITUTION = 'CURRENT_INSTITUTION',
  ASCD_INSTITUTION_SETTINGS = 'ASCD_INSTITUTION_SETTINGS',
}

export type TAppContextData = ICurrentUser | IInstitution | IUserRole | null;

interface IAppContextState {
  currentUser: ICurrentUser;
  currentUserRole: IUserRole;
  currentInstitution: IInstitution;
  ascdInstitutionSettings: IInstitution;
}

interface IAppContextAction {
  type: eActionType;
  data: TAppContextData;
}

const initialState: IAppContextState = {
  currentUser: {} as ICurrentUser,
  currentInstitution: {} as IInstitution,
  ascdInstitutionSettings: {} as IInstitution,
  currentUserRole: {
    isAuthor: false,
    isLearner: true,
    isManager: false,
    isAscdAdmin: false,
    isInstitutionalAdmin: false,
  },
};

const reducer = (state: IAppContextState, action: IAppContextAction) => {
  const { type, data } = action;

  switch (type) {
    case eActionType.CURRENT_USER:
      return { ...state, currentUser: data as ICurrentUser };
    case eActionType.CURRENT_USER_ROLE:
      return { ...state, currentUserRole: data as IUserRole };
    case eActionType.CURRENT_INSTITUTION:
      return { ...state, currentInstitution: data as IInstitution };
    case eActionType.ASCD_INSTITUTION_SETTINGS:
      return { ...state, ascdInstitutionSettings: data as IInstitution };
    case eActionType.RESET:
      return initialState;
    default:
      return state;
  }
};

const AppContext = createContext<{
  state: IAppContextState;
  dispatch: Dispatch<IAppContextAction>;
}>({
  state: initialState,
  dispatch: () => {
    //
  },
});

const AppContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<IAppContextState, IAppContextAction>>(
    reducer,
    initialState,
  );
  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };

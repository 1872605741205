import { ReactElement, ReactNode } from 'react';
import { IIcons } from '@ascd/witsby-components';
import { Box, Container, Divider, Grid, SxProps, Theme, useTheme } from '@mui/material';

import MetaTitle from '@components/MetaTitle';
import PagesSubHeader from '@components/PagesSubHeader';
import { detectDeviceIsTablet } from '@utils';

interface IMyPortfolioLayout {
  title?: string;
  children: ReactNode;
  titleViewBox?: string;
  withSidebar?: boolean;
  showSubHeader?: boolean;
  iconSx?: SxProps<Theme>;
  titleIcon?: keyof IIcons;
  sideChildren?: ReactNode;
  childrenSx?: SxProps<Theme>;
  breadCrumbsChildren?: ReactElement;
}

const MyPortfolioLayout = ({
  title,
  iconSx,
  children,
  titleIcon,
  childrenSx,
  titleViewBox,
  sideChildren,
  withSidebar = false,
  breadCrumbsChildren,
  showSubHeader = true,
}: IMyPortfolioLayout) => {
  const theme1 = useTheme();

  const isTablet = detectDeviceIsTablet();

  return (
    <>
      <MetaTitle title={title} />
      {showSubHeader && (
        <PagesSubHeader
          iconSx={iconSx}
          title={title || ''}
          titleIcon={titleIcon}
          titleViewBox={titleViewBox}
        />
      )}
      {breadCrumbsChildren && (
        <>
          <Grid container>
            <Grid item xs={12} sm={4} md={3} order={{ xs: 2, sm: 1 }} />
            <Grid item xs={12} sm={8} md={9} order={{ xs: 1, sm: 2 }}>
              {breadCrumbsChildren}
            </Grid>
          </Grid>
          <Divider sx={{ position: 'relative', width: '100%' }} />
        </>
      )}
      <Container
        maxWidth={false}
        sx={(theme) => ({
          pb: '5rem',
          px: '0.688rem',
          display: 'flex',
          height: showSubHeader ? 'calc(100% - 115px - 60px - 50px)' : 'calc(100% - 95px)',
          [theme.breakpoints.down('sm')]: {
            overflowX: 'hidden',
          },
          ...(withSidebar && {
            '&.MuiContainer-root': {
              p: 0,
            },
          }),
        })}>
        {withSidebar ? (
          children
        ) : (
          <>
            <Box
              sx={(theme) => ({
                width: isTablet ? '75px ' : 'calc((100vw - 900px)/2)',
                [theme.breakpoints.up('lg')]: {
                  minWidth: '305px',
                },
                [theme.breakpoints.between('md', 'lg')]: {
                  minWidth: '270px',
                },
                [theme.breakpoints.down('md')]: {
                  minWidth: '100px',
                },
                [theme.breakpoints.down('sm')]: {
                  width: '0px',
                  minWidth: '0px',
                },
              })}>
              {sideChildren}
            </Box>
            <Box
              sx={{
                width: isTablet ? 'calc(100vw - (86px*2))' : '950px',
                [theme1.breakpoints.up('lg')]: {
                  minWidth: '867px',
                },
                [theme1.breakpoints.down('lg')]: {
                  minWidth: '650px',
                },
                [theme1.breakpoints.down('sm')]: {
                  minWidth: 'calc(100vw - (20px))',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 3,
                  justifyContent: 'center',
                  mt: '12px',
                },
                paddingRight: '22px',
                ...childrenSx,
              }}>
              {children}
            </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default MyPortfolioLayout;

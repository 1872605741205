import Head from 'next/head';
import { SvgIcon } from '@ascd/witsby-components';
import { Box } from '@mui/material';
import ThemeProvider from '@components/ThemeProvider';

const Maintenance = () => (
  <ThemeProvider>
    <Box
      sx={(theme) => ({
        height: '100%',
        textAlign: 'center',
        color: theme.palette.grey[100],
        '& .maintenance_top_right_vector': {
          top: 0,
          right: 0,
          width: '30%',
          position: 'fixed',
        },
        '& .maintenance_bottom_left_vector': {
          left: 0,
          bottom: 0,
          width: '30%',
          position: 'fixed',
          transform: 'rotate(180deg)',
        },
      })}>
      <Head>
        <meta name="robots" content="noindex" />
        <title>Witsby is being updated...</title>
      </Head>

      <SvgIcon
        icon="WITSBY_LOGO"
        sx={{ width: 200, height: 60, position: 'fixed', top: '3%', left: '3%' }}
      />
      <img
        alt="maintenance vector"
        src="/maintenance_vector.png"
        className="maintenance_top_right_vector"
      />
      <img
        alt="maintenance vector"
        src="/maintenance_vector.png"
        className="maintenance_bottom_left_vector"
      />
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          img: {
            width: { xs: '45%', xl: 'auto' },
          },
        }}>
        <img src="/maintenance.png" alt="Witsby is being updated" />
        <Box
          sx={(theme) => ({
            mt: 5,
            fontSize: { xs: '150%', sm: '220%' },
            fontWeight: theme.font.weight.LIGHT,
            fontFamily: [theme.font.family.PRIMARY].join(','),
            span: {
              fontWeight: theme.font.weight.BOLD,
            },
          })}>
          <span>Witsby</span> is being updated...
        </Box>
        <SvgIcon
          icon="MAINTENANCE_PROGRESS"
          sx={{
            my: 1,
            width: '70%',
            height: '1%',
          }}
        />
        <Box
          sx={(theme) => ({
            fontSize: theme.font.size.SMALL,
            fontWeight: theme.font.weight.LIGHT,
            fontFamily: [theme.font.family.PRIMARY].join(','),
            display: { xs: 'block', sm: 'flex' },
            div: {
              fontWeight: theme.font.weight.BOLD,
            },
          })}>
          <div>Enhancements in Progress:</div>&nbsp;Stay Tuned for an Enriched Learning Experience!
        </Box>
      </Box>
    </Box>
  </ThemeProvider>
);

export default Maintenance;

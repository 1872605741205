import { useCallback, useContext, useEffect } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  Divider,
  IAttachments,
  IConversation,
  IMessage,
  Typography,
} from '@ascd/witsby-components'; // IAttachments,
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Tooltip } from '@mui/material';
import { cloneDeep, get, isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useInView } from 'react-intersection-observer';
import { AppContext } from '@contexts';
import GET_CONVERSATIONS_FROM_USER_ID from '@graphql/schema/getConversationsFromUserId.graphql';
import GET_UNREAD_COUNT_BY_USER_ID from '@graphql/schema/getUnreadCountByUserId.graphql';
import UPDATE_READ_MESSAGE from '@graphql/schema/mutations/updateReadyByForMessage.graphql';
import {
  createMarkup,
  handleGraphqlError,
  commonConversationsData,
  commonConversationsFilter,
} from '@utils';
import { Avatar } from '../Avatar';
import { ChatAttachment } from '../ChatAttachment';

interface IMessageProps {
  message: IMessage;
  unReadMessage?: IMessage;
  onImageLoaded?: () => void;
}
interface Styles {
  [key: string]: React.CSSProperties;
}
const Message = ({
  message,
  unReadMessage,
  onImageLoaded = () => null,
}: IMessageProps): JSX.Element => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const apolloClient = useApolloClient();

  const userHasRead = (message?.readBy ?? []).some((read) => read?.userId === currentUser.oktaId);
  const currentUserIsSender = currentUser.oktaId === message.sender.id;

  const { ref, inView } = useInView({
    triggerOnce: true,
    skip: userHasRead,
  });

  const handleReadByMessageCache = useCallback(
    ({ updateReadyByForMessage }: { updateReadyByForMessage: IMessage }) => {
      const { conversationId } = updateReadyByForMessage;

      ['GROUP', 'ASSIGNMENT', 'DIRECT'].forEach((type) => {
        // Read the cached conversation data for the current user
        const cachedAllConversationsDataList = apolloClient.readQuery({
          query: GET_CONVERSATIONS_FROM_USER_ID,
          ...commonConversationsFilter(currentUser.oktaId, type, false),
        });

        const conversationsLists = get(
          cachedAllConversationsDataList,
          'getConversationsFromUserId',
          commonConversationsData,
        );

        if (
          conversationsLists?.conversations?.some((c: IConversation) => c._id === conversationId)
        ) {
          // Deep clone the unread counts to avoid direct mutation
          const cloneUnreadCountByConversations = cloneDeep(
            cachedAllConversationsDataList?.getConversationsFromUserId
              ?.unreadCountByConversations || {},
          );

          // Increment the unread count for the conversation
          cloneUnreadCountByConversations[`${conversationId}`] =
            (cloneUnreadCountByConversations[`${conversationId}`] || 1) - 1;

          // Write the updated unread counts back to the cache
          apolloClient.writeQuery({
            query: GET_CONVERSATIONS_FROM_USER_ID,
            ...commonConversationsFilter(currentUser.oktaId, type, false),
            data: {
              ...cachedAllConversationsDataList,
              getConversationsFromUserId: {
                ...(cachedAllConversationsDataList?.getConversationsFromUserId || {}),
                unreadCountByConversations: cloneUnreadCountByConversations,
              },
            },
          });

          // Read the cached getUnreadCountByUserId data for the current user
          const cachedGetUnreadCountByUserId = apolloClient.readQuery({
            query: GET_UNREAD_COUNT_BY_USER_ID,
            variables: {
              userId: currentUser.oktaId,
            },
          });

          const getUnreadCountByUserId =
            get(cachedGetUnreadCountByUserId, 'getUnreadCountByUserId', 1) - 1;

          // Write the updated unread counts back to the cache
          apolloClient.writeQuery({
            query: GET_UNREAD_COUNT_BY_USER_ID,
            variables: {
              userId: currentUser.oktaId,
            },
            data: {
              ...cachedGetUnreadCountByUserId,
              getUnreadCountByUserId: getUnreadCountByUserId < 0 ? 0 : getUnreadCountByUserId,
            },
          });
        }
      });
    },
    [apolloClient, currentUser.oktaId],
  );

  const [updateReadMessage] = useMutation(UPDATE_READ_MESSAGE, {
    onError: handleGraphqlError,
    onCompleted: handleReadByMessageCache,
  });

  const callUpdateReadMessage = useCallback(
    (messageId: string) => {
      const readAt = new Date().toISOString();
      updateReadMessage({
        variables: {
          updateReadyByForMessageId: messageId,
          readBy: { userId: currentUser.oktaId, readAt },
        },
      });
    },
    [currentUser.oktaId, updateReadMessage],
  );

  useEffect(() => {
    if (inView) {
      callUpdateReadMessage(message?._id || '');
    }
  }, [callUpdateReadMessage, inView, message?._id]);

  const isError = message._id?.startsWith('ERROR');
  const isSending = message._id?.startsWith('SENDING');

  const noContent = isEmpty(message.content);

  const generateIndentStyles = (maxIndentLevel: number) => {
    const styles: Styles = {};
    for (let i = 1; i <= maxIndentLevel; i++) {
      styles[`& .ql-indent-${i}`] = {
        paddingLeft: `${i * 2}em`,
      };
    }
    return styles;
  };

  const getMaxIndentLevel = (content: string) => {
    const regex = /ql-indent-(\d+)/g;
    let maxLevel = 0;
    let match;
    while ((match = regex.exec(content)) !== null) {
      const level = parseInt(match[1], 10);
      if (level > maxLevel) {
        maxLevel = level;
      }
    }
    return maxLevel;
  };

  const maxIndentLevel = getMaxIndentLevel(message.content);

  return (
    <Box id={`message-${message._id}`}>
      <Box
        id={`message-${message._id}`}
        sx={{
          display: unReadMessage && unReadMessage?._id === message._id ? 'flex' : 'none',
          alignItems: 'center',
          margin: '20px 0',
        }}>
        <Divider sx={{ flexGrow: 1, borderColor: (theme) => theme.palette.primary.main }} />
        <Typography
          sx={{
            padding: '0 10px',
            color: (theme) => theme.palette.primary.main,
            borderRadius: '5px',
            fontSize: (theme) => theme.font.size.SMALL,
          }}>
          New Message
        </Typography>
        <Divider sx={{ flexGrow: 1, borderColor: (theme) => theme.palette.primary.main }} />
      </Box>

      <Box
        ref={ref}
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          color: theme.palette.grey[100],
          fontSize: theme.font.size.X_SMALL,
          fontWeight: theme.font.weight.BOLD,
        })}>
        <Avatar
          alt={message.sender.name}
          src={message.sender.avatarUrl}
          sx={(theme) => ({
            mr: 0.5,
            width: theme.size.LARGE,
            height: theme.size.LARGE,
            fontSize: theme.font.size.SMALL,
          })}
        />
        <Box
          sx={{
            maxWidth: '72%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {currentUserIsSender ? `Me (${message.sender.name})` : message.sender.name}
        </Box>
        <Box
          sx={(theme) => ({
            ml: 0.5,
            fontSize: theme.font.size.X_SMALL,
            fontWeight: theme.font.weight.LIGHT,
          })}>
          {DateTime.fromISO(message.sentAt).toFormat('hh:mm a')}
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          ml: 3,
          py: 1,
          px: 0.3,
          maxWidth: '70%',
          width: 'fit-content',
          wordBreak: 'break-word',
          color: theme.palette.grey[100],
          fontSize: theme.font.size.X_SMALL,
          fontWeight: theme.font.weight.REGULAR,
          borderRadius: theme.border.radius.SMALL,
          borderTopLeftRadius: theme.border.radius.NONE,
          ...(!noContent && {
            px: 1.3,
            background: currentUserIsSender
              ? `${theme.palette.primary.main}3b`
              : theme.palette.grey[600],
          }),
          '& p, & ul, & ol': {
            m: theme.spaces.NONE,
          },
          '& blockquote': {
            m: 1.5,
            ml: 1,
            pl: 2,
            borderLeft: '4px solid #ccc',
          },
        })}>
        {!isEmpty(message?.attachments) && (
          <ChatAttachment
            attachments={message.attachments as IAttachments[]}
            onImageLoaded={() => {
              onImageLoaded();
            }}
          />
        )}
        <Box
          sx={{
            ...((isSending || isError) && {
              display: 'flex',
              alignItems: 'center',
            }),
          }}>
          <Box>
            <Box
              sx={{
                opacity: isSending ? 0.4 : 1,
                whiteSpace: 'pre-wrap',
                ...generateIndentStyles(maxIndentLevel),
              }}
              dangerouslySetInnerHTML={createMarkup(message.content)}
            />
          </Box>
          {isError && (
            <Tooltip title="The message contain profanity" arrow describeChild>
              <ErrorIcon
                sx={(theme) => ({
                  ml: 0.5,
                  color: theme.palette.error.main,
                  fontSize: theme.font.size.X_SMALL,
                })}
              />
            </Tooltip>
          )}
          {/* {isSending && <Loader size={12} sx={{ ml: 0.5 }} />} */}
        </Box>
      </Box>
      <Box>
        {isError && (
          <Box sx={{ display: 'flex', ml: '1.25rem', alignItems: 'center' }}>
            <Box sx={(theme) => ({ fontSize: theme.font.size.XX_SMALL })}> MESSAGE NOT SENT</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Message;

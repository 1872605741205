import { ReactNode, useReducer, createContext, Dispatch, Reducer, FC, useMemo } from 'react';
import {
  Group,
  ICheckedData,
  ISideBarFilter,
  IInstitutionHierarchies,
  IInstitutionStructure,
} from '@ascd/witsby-components';
import { DateTime } from 'luxon';
import { getFirstDateOfQuarter } from '@utils';

export enum eDashboardActionType {
  RESET = 'RESET',
  DETAILS_PAGE = 'DETAILS_PAGE',
  SIDEBAR_FILTER = 'SIDEBAR_FILTER',
  SIDEBAR_API_FILTER = 'SIDEBAR_API_FILTER',
  UPDATE_SIDEBAR_FILTER = 'UPDATE_SIDEBAR_FILTER',
  UPDATE_SIDEBAR_API_FILTER = 'UPDATE_SIDEBAR_API_FILTER',
}

export interface IDetailsPage {
  type?:
    | 'LEARNINGS'
    | 'LEARNINGS_DETAIL'
    | 'COLLECTION_TYPE'
    | 'COLLECTION_TYPE_DETAIL'
    | 'LOGIN_ACTIVITY'
    | 'LOGIN_ACTIVITY_USER_DETAILS'
    | 'TOP_LEARNINGS'
    | 'HIGHEST_RATED';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detailsPageData?: any;
}

export interface ISelectedInstitution {
  label: string;
  value: string;
  hierarchiesDataList?: IInstitutionHierarchies[];
  structure?: IInstitutionStructure[];
}

export type TDashboardContextData = IDetailsPage | ISideBarFilter | Partial<ISideBarFilter> | null;

interface IDashboardContextState {
  sideBarFilter: ISideBarFilter;
  sideBarApiFilter: ISideBarFilter;
  detailsPage: IDetailsPage | null;
}

interface IDashboardContextAction {
  type: eDashboardActionType;
  data: TDashboardContextData;
}
const startDate = getFirstDateOfQuarter(true);

export const initialSideBarFilter: ISideBarFilter = {
  dates: {
    startDate,
    endDate: DateTime.local().endOf('day').toUTC().toISO(),
  },
  includeHParentId: [],
  includeSParentId: [],
  createdByOrganizationId: [],
  assignedBy: ['ASSIGNED', 'SELF_ENROLLED'],
  internalId1: [] as IInstitutionHierarchies[],
  selectedSchoolIds: [] as IInstitutionHierarchies[],
  checkedList: [] as IInstitutionHierarchies[],
  selectedInstitution: {} as ISelectedInstitution,
  groupCheckList: [] as Group[],
  selectedGroup: [] as Group[],
  checkedGroupAcrossSchool: [] as ICheckedData[],
};

const initialState: IDashboardContextState = {
  detailsPage: null,
  sideBarFilter: initialSideBarFilter,
  sideBarApiFilter: initialSideBarFilter,
};

const reducer = (state: IDashboardContextState, action: IDashboardContextAction) => {
  const { type, data } = action;

  switch (type) {
    case eDashboardActionType.DETAILS_PAGE:
      return { ...state, detailsPage: data as IDetailsPage };
    case eDashboardActionType.SIDEBAR_FILTER:
      return { ...state, sideBarFilter: data as ISideBarFilter };
    case eDashboardActionType.SIDEBAR_API_FILTER:
      return { ...state, sideBarApiFilter: data as ISideBarFilter };
    case eDashboardActionType.UPDATE_SIDEBAR_FILTER:
      return {
        ...state,
        sideBarFilter: {
          ...state.sideBarFilter,
          ...(data as ISideBarFilter),
        },
      };
    case eDashboardActionType.UPDATE_SIDEBAR_API_FILTER:
      return {
        ...state,
        sideBarApiFilter: {
          ...state.sideBarApiFilter,
          ...(data as ISideBarFilter),
        },
      };
    case eDashboardActionType.RESET:
      return initialState;
    default:
      return state;
  }
};

const DashboardContext = createContext<{
  state: IDashboardContextState;
  dispatch: Dispatch<IDashboardContextAction>;
}>({
  state: initialState,
  dispatch: () => {
    //
  },
});

const DashboardContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<IDashboardContextState, IDashboardContextAction>>(
    reducer,
    initialState,
  );
  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};

const DashboardContextConsumer = DashboardContext.Consumer;

export { DashboardContext, DashboardContextProvider, DashboardContextConsumer };

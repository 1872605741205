import {
  useMemo,
  useState,
  ReactNode,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react';
import { IUserInfo } from '@ascd/witsby-components';
import get from 'lodash/get';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import { io, Socket as ISocket } from 'socket.io-client';
import { sleep } from '@utils/course';
import { AppContext, eActionType } from './appContext';

interface ISocketProvider {
  children: ReactNode;
}

export const SocketContext = createContext<{
  socket?: ISocket;
  onlineUsers: string[];
  typingUsers: { [key: string]: IUserInfo[] };
  connectSocket: (accessToken: string, userinfo: string) => void;
  disconnectSocket: () => void;
}>({
  onlineUsers: [],
  typingUsers: {},
  connectSocket: () => null,
  disconnectSocket: () => null,
});

export const SocketProvider = ({ children }: ISocketProvider) => {
  const [socket, setSocket] = useState<null | ISocket>(null);
  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext);
  const [retryCount, setRetryCount] = useState(0);
  const [onlineUsers, setOnlineUsers] = useState<string[]>([]);
  const [typingUsers, setTypingUsers] = useState<{
    [key: string]: IUserInfo[];
  }>({});

  const dispatchCurrentUser = useCallback(
    (res: undefined) => {
      dispatch({
        type: eActionType.CURRENT_USER,
        data: {
          ...currentUser,
          ...(res || {}),
        },
      });
    },
    [currentUser, dispatch],
  );

  const connectSocket = useCallback(
    (accessToken: string, userinfo: string) => {
      let socketInstance: ISocket | null = null;

      socketInstance = io(process.env.WEBSOCKET_URL || '', {
        withCredentials: true,
        extraHeaders: {
          userinfo,
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // getOnlineUsers
      socketInstance.on('getOnlineUsers', (payload: undefined) => {
        setOnlineUsers(get(payload, 'onlineUsers', []));
      });

      // onTypingStart
      socketInstance.on('onTypingStart', (payload: { conversationId: string; user: IUserInfo }) => {
        const { conversationId, user } = payload;
        setTypingUsers((prevTypingUsers) => {
          const users = prevTypingUsers[`${conversationId}`] || [];
          return {
            ...prevTypingUsers,
            [`${conversationId}`]: [...users, user],
          };
        });
      });

      // onTypingStop
      socketInstance.on('onTypingStop', (payload: { conversationId: string; user: IUserInfo }) => {
        const { conversationId, user } = payload;
        setTypingUsers((prevTypingUsers) => {
          const users = uniqBy(prevTypingUsers[`${conversationId}`] || [], 'id').filter(
            (u: IUserInfo) => u.id !== user.id,
          );

          if (users.length) {
            return {
              ...prevTypingUsers,
              [`${conversationId}`]: users,
            };
          }

          return omit(prevTypingUsers, [`${conversationId}`]);
        });
      });

      socketInstance.on('onBlockOrUnblockUser', dispatchCurrentUser);

      socketInstance.on('connect_error', async (err) => {
        console.log(`connect_error due to ${err.message}`);
        const oktaTokenStorage = localStorage.getItem('okta-token-storage');
        if (oktaTokenStorage) {
          const storedToken = JSON.parse(oktaTokenStorage);
          const retryAccessToken = get(storedToken, 'accessToken.accessToken');

          if (accessToken) {
            const retryUserinfo = localStorage.getItem('header-info') || '';
            if (retryCount < 5) {
              // Retry up to 5 times
              await sleep(3000);
              setRetryCount((prev) => prev + 1);

              connectSocket(retryAccessToken, retryUserinfo);
            }
          }
        }
      });

      socketInstance.on('connect', () => {
        setRetryCount(0); // Reset retry count on successful connection
      });

      setSocket(socketInstance);
    },
    [retryCount, dispatchCurrentUser],
  );

  const disconnectSocket = useCallback(() => {
    if (socket) {
      socket.off('onTypingStop');
      socket.off('onTypingStart');
      socket.off('connect_error');
      socket.off('getOnlineUsers');
      socket.off('onBlockOrUnblockUser');
      socket.disconnect();
      setSocket(null);
    }
  }, [socket]);

  useEffect(
    () => () => {
      disconnectSocket();
    },
    [disconnectSocket],
  );

  const value = useMemo(
    () => ({
      socket: socket as ISocket,
      onlineUsers,
      typingUsers,
      connectSocket,
      disconnectSocket,
    }),
    [onlineUsers, typingUsers, socket, connectSocket, disconnectSocket],
  );

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

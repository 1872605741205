import { ElementType, Fragment, useEffect } from 'react';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { ApolloProvider } from '@apollo/client';
import forEach from 'lodash/forEach';
import { NextAdapter } from 'next-query-params';
import { ToastContainer } from 'react-toastify';
import { QueryParamProvider } from 'use-query-params';

import '@styles/index.css';
import '@mui/material/styles';
import 'video.js/dist/video-js.css';
import 'react-toastify/dist/ReactToastify.css';
import { ChatAndCommunicationPopup } from '@components/ChatAndCommunication';
import MainLayout from '@components/Layout';
import Maintenance from '@components/Maintenance';
import MetaTitle from '@components/MetaTitle';
import { NetworkStatus } from '@components/NetworkStatus';
import NewVersionNotification from '@components/NewVersionNotification';
import ThemeProvider from '@components/ThemeProvider';
import { AppContextProvider, DashboardContextProvider, DrawerContextProvider } from '@contexts';
import { ChatContextProvider } from '@contexts/chatContext';
import { SocketProvider } from '@contexts/SocketContext';
import { useApollo } from '@graphql/apolloClient';
import useVersionCheck from '@utils/hooks/useVersionCheck';

export type PageWithMainLayoutType = NextPage & {
  Layout: typeof MainLayout;
};

const OktaSecurity = dynamic(() => import('../components/Okta/OktaSecurity'), {
  ssr: false,
});

interface AppLayoutProps extends AppProps {
  Component: PageWithMainLayoutType;
}

const App = ({ Component, pageProps }: AppLayoutProps) => {
  const router = useRouter();
  const newVersionAvailable = useVersionCheck();
  const apolloClient = useApollo(pageProps);

  const showChatPopUp = router.pathname !== '/chat-and-communication';

  useEffect(() => {
    const MINUTE_MS = 60000;
    const interval = setInterval(() => {
      if (typeof window !== 'undefined') {
        const defaultCacheImages = localStorage.getItem('images');
        if (defaultCacheImages) {
          const now = new Date();
          const localStorageImages = JSON.parse(defaultCacheImages);
          forEach(localStorageImages, (localStorageImage, key) => {
            if (now.getTime() > localStorageImage.expiry) {
              delete localStorageImages[`${key}`];
            }
          });
          localStorage.setItem('images', JSON.stringify(localStorageImages || {}));
        }
      }
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  const maintenanceModeEnabled = process.env.MAINTENANCE_MODE_ENABLED;
  const isMaintenanceModeEnabled = maintenanceModeEnabled?.toLowerCase() === 'true';

  if (isMaintenanceModeEnabled) return <Maintenance />;

  const Layout = (Component.Layout ? Component.Layout : Fragment) as ElementType;

  return (
    <QueryParamProvider adapter={NextAdapter}>
      <ApolloProvider client={apolloClient}>
        <AppContextProvider>
          <SocketProvider>
            <DashboardContextProvider>
              <DrawerContextProvider>
                <ChatContextProvider>
                  <ThemeProvider>
                    <OktaSecurity>
                      <MetaTitle showDefaultTags />
                      <Layout>
                        <NetworkStatus />
                        {showChatPopUp && <ChatAndCommunicationPopup />}
                        <Component {...pageProps} />
                      </Layout>
                    </OktaSecurity>
                    <NewVersionNotification newVersionAvailable={newVersionAvailable} />
                  </ThemeProvider>
                </ChatContextProvider>
              </DrawerContextProvider>
            </DashboardContextProvider>
          </SocketProvider>
        </AppContextProvider>
      </ApolloProvider>
      <ToastContainer />
    </QueryParamProvider>
  );
};

export default App;

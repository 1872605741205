import { useEffect, useState } from 'react';
import { Alert, Box } from '@mui/material';

const NetworkStatus = () => {
  const [showAlert, setShowAlert] = useState<undefined | 'ONLINE' | 'OFFLINE'>(undefined);

  useEffect(() => {
    const notifyOnline = () => {
      setShowAlert('ONLINE');
      setTimeout(() => setShowAlert(undefined), 3000);
    };
    const notifyOffline = () => {
      setShowAlert('OFFLINE');
    };

    window.addEventListener('online', notifyOnline);
    window.addEventListener('offline', notifyOffline);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('online', notifyOnline);
      window.removeEventListener('offline', notifyOffline);
    };
  }, []);

  if (!showAlert) return null;

  return (
    <Box
      sx={{
        top: 70,
        width: '100%',
        zIndex: 9999,
        display: 'flex',
        position: 'fixed',
        justifyContent: 'center',
      }}>
      <Alert
        onClose={() => setShowAlert(undefined)}
        severity={showAlert === 'ONLINE' ? 'success' : 'error'}>
        {showAlert === 'ONLINE'
          ? 'You are back online!'
          : 'You are offline! check your internet connection'}
      </Alert>
    </Box>
  );
};

export default NetworkStatus;

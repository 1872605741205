import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import QRCode from 'qrcode';

const pdfGenerator = async (
  userName: string,
  courseName: string,
  clockHour: string,
  date: string,
  certificateUrl: string,
) => {
  try {
    const url = '/certificate.pdf';
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    pdfDoc.registerFontkit(fontkit);
    const fontUrl = '/fonts/arial.ttf';
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    const arialFont = await pdfDoc.embedFont(fontBytes);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const { width, height } = firstPage.getSize();

    const userNameWidth = arialFont.widthOfTextAtSize(userName, 24);
    const courseNameWidth = arialFont.widthOfTextAtSize(courseName, 24);
    const clockHourWidth = arialFont.widthOfTextAtSize(clockHour, 14);

    firstPage.drawText(userName, {
      x: (width - userNameWidth) / 2,
      y: height - 240,
      size: 24,
      font: arialFont,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(courseName, {
      x: (width - courseNameWidth) / 2,
      y: height - 385,
      size: 24,
      font: arialFont,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(clockHour, {
      x: (width - clockHourWidth) / 2,
      y: 145,
      size: 14,
      font: arialFont,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(date, {
      x: 592,
      y: 145,
      size: 14,
      font: arialFont,
      color: rgb(0, 0, 0),
    });

    // Generate QR code based on the certificate URL
    const qrCodeImageUrl = await QRCode.toDataURL(certificateUrl, { type: 'image/png' });

    // Convert the QR code to an image that can be embedded in the PDF
    const qrCodeImageBytes = await fetch(qrCodeImageUrl).then((res) => res.arrayBuffer());
    const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);

    // Position the QR code image
    const qrCodeWidth = 60;
    const qrCodeHeight = 60;
    const padding = 40;
    const bottomPadding = 50;
    const xPosition = width - qrCodeWidth - padding;

    firstPage.drawImage(qrCodeImage, {
      x: xPosition,
      y: bottomPadding,
      width: qrCodeWidth,
      height: qrCodeHeight,
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(blob);

    window.open(pdfUrl, '_blank', 'noreferrer');
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};

export default pdfGenerator;

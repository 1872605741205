import { ICourseStructure } from '@ascd/witsby-components';
import groupBy from 'lodash/groupBy';
import { STRUCTURE_TYPE } from '@constants';

function useGetChapters(
  structures: ICourseStructure[],
  currentChapterId?: string,
  isLearningList?: boolean,
): {
  chapters: ICourseStructure[];
  nextChapter?: ICourseStructure;
  firstChapter?: ICourseStructure;
  previousChapter?: ICourseStructure;
} {
  if (!structures?.length) return { chapters: [], firstChapter: {} as ICourseStructure };

  let nextChapter;
  let firstChapter;
  let previousChapter;
  const sections = structures.filter((structure) => structure.type === STRUCTURE_TYPE.SECTION);
  const chapters = structures.filter((structure) => structure.type === STRUCTURE_TYPE.CHAPTER);
  const chaptersGroupByParentId = groupBy(chapters, (item) => item.parentId);

  if (isLearningList) {
    if (!chapters.length) return { chapters };
    firstChapter = chapters[0];

    if (currentChapterId) {
      const index = chapters.findIndex((chapter) => chapter.id === currentChapterId);
      if (index > -1 && chapters[index + 1]) {
        nextChapter = chapters[index + 1];
      }
      if (index > -1 && chapters[index - 1]) {
        previousChapter = chapters[index - 1];
      }
    }

    return {
      chapters,
      nextChapter,
      firstChapter,
      previousChapter,
    };
  }

  chapters.length = 0;

  sections.forEach((section) => {
    if (chaptersGroupByParentId[section.id]?.length) {
      chaptersGroupByParentId[section.id].forEach((structure) => {
        chapters.push(structure);
      });
    }
  });

  if (chapters.length) {
    firstChapter = chapters[0];

    if (currentChapterId) {
      const index = chapters.findIndex((chapter) => chapter.id === currentChapterId);
      if (index > -1 && chapters[index + 1]) {
        nextChapter = chapters[index + 1];
      }
      if (index > -1 && chapters[index - 1]) {
        previousChapter = chapters[index - 1];
      }
    }
  }

  return {
    chapters,
    nextChapter,
    firstChapter,
    previousChapter,
  };
}

export default useGetChapters;

import {
  IInstitutionStructure,
  IInstitutionHierarchies,
  TInstitutionStructureType,
} from '@ascd/witsby-components';
import { isEmpty } from 'lodash';

export const getParentHierarchy = (
  hierarchy: IInstitutionHierarchies,
  type: TInstitutionStructureType,
  structures: IInstitutionStructure[],
  hierarchies: IInstitutionHierarchies[],
  id?: string,
): null | IInstitutionHierarchies => {
  const structureTypes = structures.map((structure) => structure.type);
  if (!structureTypes.includes(type)) return null;

  const findStructure = (structureId: string) => structures.find((s) => s.id === structureId);
  const findHierarchy = (hierarchyId: string) => hierarchies.find((h) => h.id === hierarchyId);

  const findParentHierarchy = (
    currentHierarchy: IInstitutionHierarchies,
  ): null | IInstitutionHierarchies => {
    const structure = findStructure(currentHierarchy.structureId);
    if (!structure) return null;

    if (id && structure.id === id) return currentHierarchy;
    if (!id && structure.type === type) return currentHierarchy;

    const parentHierarchy = findHierarchy(currentHierarchy.parentId);
    if (!parentHierarchy) return null;

    return findParentHierarchy(parentHierarchy);
  };

  const parentHierarchy = findParentHierarchy(hierarchy);
  return parentHierarchy;
};

export const getChildHierarchies = (
  hierarchy: IInstitutionHierarchies,
  hierarchies: IInstitutionHierarchies[],
  getChildren = false,
): IInstitutionHierarchies[] => {
  const hierarchyIds = [hierarchy.id];

  const findChildHierarchies = (currentHierarchy: IInstitutionHierarchies) => {
    const childHierarchies = hierarchies.filter((h) => h.parentId === currentHierarchy.id);
    if (isEmpty(childHierarchies)) return;

    childHierarchies.forEach((h) => {
      hierarchyIds.push(h.id);
      findChildHierarchies(h);
    });
  };

  findChildHierarchies(hierarchy);

  // if true than it will return children hierarchies for current passed hierarchy
  if (getChildren) return hierarchies.filter((h) => hierarchyIds.includes(h.id));

  // if false than it will return all other hierarchies except the children hierarchies and current passed hierarchy
  return hierarchies.filter((h) => !hierarchyIds.includes(h.id));
};

export const updateHierarchyData = (
  hierarchy: IInstitutionHierarchies,
  hierarchies: IInstitutionHierarchies[],
  updatedHierarchy: Partial<IInstitutionHierarchies>,
): IInstitutionHierarchies[] =>
  hierarchies.map((hi) => {
    if (hi.id === hierarchy.id) return { ...hi, ...updatedHierarchy };
    return hi;
  });

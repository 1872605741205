import { useRouter } from 'next/router';
import { SvgIcon } from '@ascd/witsby-components';
import { Box } from '@mui/material';

const SelectConversation = (): JSX.Element => {
  const router = useRouter();
  const isPopUpChat = router.pathname !== '/chat-and-communication';

  return (
    <Box
      sx={{
        display: 'flex',
        maxWidth: '100%',
        flexDirection: 'column',
        p: isPopUpChat ? 0 : 1.5,
        justifyContent: 'space-between',
        height: isPopUpChat ? '100%' : '90%',
      }}>
      <Box
        sx={(theme) => ({
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          mb: isPopUpChat ? 0 : 2,
          justifyContent: 'center',
          color: theme.palette.grey[200],
          fontSize: theme.font.size.SMALL,
          svg: {
            fontSize: theme.font.size.H1,
          },
          div: {
            mt: 1,
            fontSize: theme.font.size.LARGE,
          },
          span: {
            color: theme.palette.grey[700],
          },
        })}>
        <SvgIcon
          icon="CHAT_ICON"
          sx={{ circle: { fill: (theme) => theme.palette.primary.main } }}
        />
        <div>Welcome to Witsby Chat!</div>
        <span>Choose or add conversation from left panel to start a chat</span>
      </Box>
    </Box>
  );
};

export default SelectConversation;

import { useCallback, useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { CustomUserClaim } from '@okta/okta-auth-js';
import GET_IMAGE_URL from '@graphql/schema/getImageUrl.graphql';

const usePresignedImage = (imageUrl: CustomUserClaim | CustomUserClaim[] | string) => {
  const apolloClient = useApolloClient();
  const [presignedImageUrl, setPresignedImageUrl] = useState('');

  const fetchImage = useCallback(
    async (imageName: string) => {
      const response = await apolloClient.query({
        query: GET_IMAGE_URL,
        variables: { imageName },
        fetchPolicy: 'network-only',
      });

      return response?.data?.getImageUrl?.url || '';
    },
    [apolloClient],
  );

  useEffect(() => {
    if (imageUrl) {
      const isValidUrl =
        (imageUrl as string).startsWith('http') || (imageUrl as string).startsWith('https');
      if (isValidUrl) {
        setPresignedImageUrl(imageUrl as string);
      } else {
        fetchImage(imageUrl as string).then((fetchedImageSrc) =>
          setPresignedImageUrl(fetchedImageSrc),
        );
      }
    } else {
      setPresignedImageUrl('');
    }
  }, [imageUrl, fetchImage]);

  return [presignedImageUrl];
};

export default usePresignedImage;

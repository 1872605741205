import { ICheckedData } from '@ascd/witsby-components';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';

export const progressStatusColorCodes = [
  { colorCode: '#03c04a', text: 'Completed' },
  { colorCode: '#ffff00', text: 'In Progress' },
  { colorCode: '#e2252b', text: 'Zero Progress' },
];

export const DASHBOARD_FILTER_DEFAULT_CHECKED_DATA: ICheckedData[] = [
  {
    _id: 'NOT_STARTED',
    name: 'Zero Progress',
  },
  {
    _id: 'IN_PROGRESS',
    name: 'In-Progress',
  },
  {
    _id: 'COMPLETED',
    name: 'Completed',
  },
];

export type T_DASHBOARD_FILTER_TYPES =
  | 'COURSES'
  | 'LEARNING_LISTS'
  | 'STRATEGY'
  | 'LEARNING_ITEMS'
  | 'EL_MAGAZINES'
  | 'EL_MAGAZINE'
  | 'EBOOKS'
  | 'WEBINARS'
  | 'WEBINAR'
  | 'RESOURCES';

export const DASHBOARD_FILTER_TYPES: ICheckedData[] = [
  {
    _id: 'COURSES',
    name: 'Course',
  },
  {
    _id: 'LEARNING_LISTS',
    name: 'Learning List',
  },
  {
    _id: 'LEARNING_ITEMS',
    name: 'Learning Item',
  },
  {
    _id: 'STRATEGY',
    name: 'Strategy',
  },
  {
    _id: 'EL_MAGAZINE',
    name: 'El Magazine',
  },
  {
    _id: 'EBOOK',
    name: 'EBooks',
  },
  {
    _id: 'WEBINAR',
    name: 'Webinar',
  },
];

export const DASHBOARD_COLLECTION_TYPES: ICheckedData[] = [
  {
    _id: 'COURSES',
    name: 'Courses',
  },
  {
    _id: 'LEARNING_LISTS',
    name: 'Learning Lists',
  },
  {
    _id: 'STRATEGY',
    name: 'Strategies',
  },
  {
    _id: 'LEARNING_ITEMS',
    name: 'Learning Items',
  },
  {
    _id: 'EL_MAGAZINE',
    name: 'El Magazines',
  },
  {
    _id: 'EBOOKS',
    name: 'EBooks',
  },
  {
    _id: 'WEBINARS',
    name: 'Webinars',
  },
];

export const DASHBOARD_COUNTS_TYPES = {
  COURSE: 'Course',
  COURSES: 'Course',
  LEARNING_LISTS: 'Learning List',
  LIST: 'Learning List',
  STRATEGY: 'Strategy',
  LEARNING_ITEMS: 'Learning Item',
  ITEM: 'Learning Item',
  WEBINAR: 'Webinar',
  WEBINARS: 'Webinar',
  EBOOK: 'EBook',
  EBOOKS: 'EBook',
  EL_MAGAZINE: 'El Magazine',
  EL_MAGAZINES: 'El Magazine',
  RESOURCES: 'Resource',
};

export const getDashboardProgressStatus = (
  type:
    | string
    | 'COURSE'
    | 'COURSES'
    | 'ITEM'
    | 'LEARNING_ITEMS'
    | 'LEARNING_ITEM'
    | 'LIST'
    | 'LEARNING_LISTS'
    | 'INSTITUTIONAL_LEARNING_LIST'
    | 'STRATEGY'
    | 'WEBINAR'
    | 'EBOOK'
    | 'EL_MAGAZINE'
    | 'RESOURCES',

  status: string | undefined,
): string => {
  if (
    type === 'COURSE' ||
    type === 'COURSES' ||
    type === 'LIST' ||
    type === 'LEARNING_LISTS' ||
    type === 'INSTITUTIONAL_LEARNING_LIST' ||
    type === 'STRATEGY'
  ) {
    if (status === 'ADD' || status === 'ENROLL') {
      return 'Zero Progress';
    }
    if (status === 'RESUME' || status === 'START') {
      return 'In Progress';
    }
    if (status === 'COMPLETED') {
      return 'Completed';
    }
  }
  if (type === 'ITEM' || type === 'LEARNING_ITEMS' || type === 'LEARNING_ITEM') {
    if (status === 'ENROLL') {
      return 'Zero Progress';
    }
    if (status === 'STARTED') {
      return 'In Progress';
    }
    if (status === 'COMPLETED') {
      return 'Completed';
    }
  }
  if (type === 'WEBINAR' || type === 'EBOOK' || type === 'EL_MAGAZINE') {
    return 'Viewed';
  }
  return '-';
};

export const getDashboardProgress = (
  type:
    | 'COURSE'
    | 'COURSES'
    | 'ITEM'
    | 'LEARNING_ITEMS'
    | 'LIST'
    | 'LEARNING_LISTS'
    | 'STRATEGY'
    | 'WEBINAR'
    | 'EBOOK'
    | 'EL_MAGAZINE'
    | 'RESOURCES',
  collectionStatus: string | undefined,
  totalEarnedTime: string,
  totalAllocatedTime: string,
): string => {
  if (
    type === 'COURSE' ||
    type === 'COURSES' ||
    type === 'LIST' ||
    type === 'LEARNING_LISTS' ||
    type === 'STRATEGY'
  ) {
    if (collectionStatus === 'ADD' || collectionStatus === 'ENROLL') {
      return `0m/${totalAllocatedTime}`;
    }
    if (collectionStatus === 'RESUME' || collectionStatus === 'START') {
      return `${totalEarnedTime}/${totalAllocatedTime}`;
    }
    if (collectionStatus === 'COMPLETED') {
      return `${totalAllocatedTime}/${totalAllocatedTime}`;
    }
  }
  if (type === 'ITEM' || type === 'LEARNING_ITEMS' || type === 'RESOURCES') {
    if (collectionStatus === 'STARTED') {
      return `${totalEarnedTime}/${totalAllocatedTime}`;
    }
    if (collectionStatus === 'COMPLETED') {
      return `${totalAllocatedTime}/${totalAllocatedTime}`;
    }
  }
  return 'N/A';
};

export const DASHBOARD_ASSIGNED_BY = [
  {
    _id: 'ASSIGNED',
    name: 'Assigned',
  },
  {
    _id: 'SELF_ENROLLED',
    name: 'Self Enrolled',
  },
];

export const getUserNameDashboard = (userDetails: { firstName: string; lastName: string }) => {
  if (isEmpty(userDetails)) return '';

  const { firstName, lastName } = userDetails;
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;
  return lastName;
};

export const getIcon = (type: T_DASHBOARD_FILTER_TYPES): string => {
  if (type === 'COURSES') {
    return 'COURSE_COMPLETE';
  }
  if (type === 'STRATEGY') {
    return 'STRATEGIES';
  }
  if (type === 'LEARNING_LISTS' || type === 'LEARNING_ITEMS') {
    return 'LEARNING_LIST';
  }
  return 'WEBINAR';
};

export const dateFormat = (
  date: string | Date,
  format: 'dd-MMM-yyyy' | 'mm/dd/yyyy' | '-',
): string => {
  if (format === 'dd-MMM-yyyy') {
    return DateTime.fromJSDate(new Date(date))?.toFormat(`''dd'-'LLL'-'yyyy''`);
  }
  if (format === 'mm/dd/yyyy') {
    return DateTime.fromJSDate(new Date(date)).toFormat(`''LL'/'dd'/'yyyy'`);
  }
  return '-';
};

export const scrollToTop = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

export const getFirstDateOfQuarter = (isLuxonFormat = false): string | Date => {
  const today = new Date();
  const currentMonth = today.getMonth();
  const quarterStartMonth = Math.floor(currentMonth / 3) * 3; // Round down to the nearest multiple of 3

  const firstDateOfQuarter = new Date(today.getFullYear(), quarterStartMonth, 1);

  if (isLuxonFormat)
    return DateTime.fromJSDate(firstDateOfQuarter).toUTC().toISO() as string | Date;
  return firstDateOfQuarter;
};

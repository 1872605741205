import { MouseEvent, useCallback, useContext, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useApolloClient, useMutation } from '@apollo/client';
import { IConversation, IUserInfo, SearchBar, Switch } from '@ascd/witsby-components';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { IconButton, Link } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import trim from 'lodash/trim';
import { AppContext, SocketContext, eActionType } from '@contexts';
import { ChatContext, eChatActionType } from '@contexts/chatContext';
import GET_CONVERSATIONS_FROM_USER_ID from '@graphql/schema/getConversationsFromUserId.graphql';
import BLOCK_UNBLOCK_USER from '@graphql/schema/mutations/blockOrUnblockUser.graphql';
import CREATE_CONVERSATION from '@graphql/schema/mutations/createConversation.graphql';
import {
  showToast,
  handleGraphqlError,
  commonConversationsData,
  commonConversationsFilter,
  findConversationIfAlreadyExist,
} from '@utils';
import { Avatar } from '../Avatar';

type Order = 'asc' | 'desc';

interface HeadCell {
  id: keyof IUserInfo;
  label: string;
  width?: string;
  isSorting?: boolean;
  align?: string;
}

interface EnhancedTableProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IUserInfo) => void;
  order: Order;
  orderBy: string;
  isPopUpChat: boolean;
  showBlockUser: boolean;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, showBlockUser, isPopUpChat, onRequestSort } = props;
  const createSortHandler = (property: keyof IUserInfo) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      width: '40%',
      label: 'Name',
      isSorting: true,
    },
    {
      id: 'schoolId',
      label: 'Direct Message',
      isSorting: false,
      align: 'center',
    },
    ...(!isPopUpChat
      ? ([
          {
            id: 'email',
            label: 'Email',
            isSorting: true,
            width: '40%',
          },
        ] as HeadCell[])
      : ([] as HeadCell[])),
    ...(showBlockUser
      ? ([
          {
            id: 'districtId',
            label: 'Block',
            isSorting: false,
            align: 'center',
          },
        ] as HeadCell[])
      : ([] as HeadCell[])),
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            width={headCell?.width || '20%'}
            align={headCell?.align ? 'center' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={(theme) => ({
              color: theme.palette.grey[700],
              fontSize: theme.font.size.SMALL,
              fontWeight: theme.font.weight.BOLD,
              [theme.breakpoints.down('md')]: {
                p: 0.5,
                width: headCell?.width || '10%',
              },
            })}>
            <TableSortLabel
              active={orderBy === headCell.id}
              hideSortIcon={!headCell.isSorting}
              tabIndex={headCell.isSorting ? 0 : 1}
              direction={orderBy === headCell.id ? order : 'asc'}
              sx={(theme) => ({
                '&:focus': {
                  border: theme.border.radius.SMALL,
                  background: `${theme.palette.primary.main}33`,
                },
              })}
              {...(headCell.isSorting && {
                onClick: createSortHandler(headCell.id),
              })}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface IParticipantsProps {
  showBlockUser?: boolean;
  participants: IUserInfo[];
}

const Participants = ({
  showBlockUser = false,
  participants: defaultParticipants,
}: IParticipantsProps) => {
  const [page] = useState(0);
  const [rowsPerPage] = useState(200);
  const [order, setOrder] = useState<Order>('asc');
  const [searchValue, setSearchValue] = useState('');
  const [orderBy, setOrderBy] = useState<keyof IUserInfo>('name');

  const router = useRouter();
  const apolloClient = useApolloClient();

  const isPopUpChat = router.pathname !== '/chat-and-communication';

  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext);
  const { onlineUsers } = useContext(SocketContext);
  const { dispatch: chatDispatch } = useContext(ChatContext);

  const dispatchCurrentUser = (res: undefined) => {
    dispatch({
      type: eActionType.CURRENT_USER,
      data: {
        ...currentUser,
        ...get(res, 'blockOrUnblockUser', {}),
      },
    });
  };

  const handleConversationCache = useCallback(
    ({ createConversation }: { createConversation: IConversation }) => {
      ['GROUP', 'ASSIGNMENT', 'DIRECT'].forEach((type) => {
        // Read the cached conversation data for the current user
        const cachedAllConversationsDataList = apolloClient.readQuery({
          query: GET_CONVERSATIONS_FROM_USER_ID,
          ...commonConversationsFilter(currentUser.oktaId, type, false),
        });

        const conversationsLists = get(
          cachedAllConversationsDataList,
          'getConversationsFromUserId',
          commonConversationsData,
        );

        if (
          !conversationsLists?.conversations?.some(
            (c: IConversation) => c._id === createConversation._id,
          ) &&
          createConversation.type === type
        ) {
          // Deep clone the conversations to avoid direct mutation
          const cloneConversations: IConversation[] = cloneDeep(
            cachedAllConversationsDataList?.getConversationsFromUserId?.conversations || [],
          );

          // Push the new conversation
          cloneConversations.push(createConversation);

          // Write the updated conversations back to the cache
          apolloClient.writeQuery({
            query: GET_CONVERSATIONS_FROM_USER_ID,
            ...commonConversationsFilter(currentUser.oktaId, type, false),
            data: {
              ...cachedAllConversationsDataList,
              getConversationsFromUserId: {
                ...(cachedAllConversationsDataList?.getConversationsFromUserId || {}),
                conversations: cloneConversations,
              },
            },
          });
        }
      });
    },
    [apolloClient, currentUser.oktaId],
  );

  const [createConversation] = useMutation(CREATE_CONVERSATION, {
    onCompleted: (data) => {
      const createdConversation: IConversation = get(data, 'createConversation');
      const userName = createdConversation.participants.find(
        (participant) => participant.userInfo.id !== currentUser.oktaId,
      );
      handleConversationCache({
        createConversation: {
          ...createdConversation,
          ...(userName && {
            title: userName.userInfo.name || createdConversation.title || '',
            avatar: userName.userInfo?.avatarUrl || createdConversation?.avatar || '',
          }),
        },
      });
      chatDispatch({
        data: {
          type: 'CHAT',
          detailsPageData: {
            conversation: createdConversation,
          },
        },
        type: 'DETAILS_PAGE' as eChatActionType.DETAILS_PAGE,
      });
      showToast('Conversation created successfully');
    },
    onError: handleGraphqlError,
  });

  const [updateBlockUnBlockUser] = useMutation(BLOCK_UNBLOCK_USER, {
    onError: handleGraphqlError,
    onCompleted: dispatchCurrentUser,
  });

  const handleDirectMessage = (selectedUser: IUserInfo) => {
    const cachedConversations = apolloClient.readQuery({
      query: GET_CONVERSATIONS_FROM_USER_ID,
      ...commonConversationsFilter(currentUser.oktaId, 'DIRECT', false),
    });
    const directMessagesConversations = get(
      cachedConversations,
      'getConversationsFromUserId.conversations',
      [],
    );
    const conversationIfAlreadyExist = findConversationIfAlreadyExist(
      directMessagesConversations,
      selectedUser.id,
    );

    if (conversationIfAlreadyExist?._id) {
      chatDispatch({
        data: {
          type: 'CHAT',
          detailsPageData: {
            conversation: conversationIfAlreadyExist,
          },
        },
        type: 'DETAILS_PAGE' as eChatActionType.DETAILS_PAGE,
      });
      return;
    }

    createConversation({
      variables: {
        createConversationInput: {
          title: '',
          avatar: '',
          type: 'DIRECT',
          status: 'ACTIVE',
          chatType: 'EVERYONE',
          createdBy: {
            id: currentUser?.oktaId,
            name: currentUser?.name,
            email: currentUser?.email,
            avatarUrl: currentUser?.avatarUrl,
            schoolGrades: currentUser?.grades,
            schoolId: currentUser?.schoolId || '',
            districtId: currentUser?.districtId || '',
            organization: currentUser?.organization || {},
            witsbyContractGroupId: currentUser?.witsbyContractGroupId,
          },
          participants: [
            {
              role: 'ADMIN',
              userInfo: {
                id: currentUser?.oktaId,
                name: currentUser?.name,
                email: currentUser?.email,
                avatarUrl: currentUser?.avatarUrl,
                schoolGrades: currentUser?.grades,
                schoolId: currentUser?.schoolId || '',
                organization: currentUser?.organization,
                districtId: currentUser?.districtId || '',
                witsbyContractGroupId: currentUser?.witsbyContractGroupId,
              },
            },
            {
              role: 'ADMIN',
              userInfo: {
                id: selectedUser?.id,
                name: selectedUser?.name,
                email: selectedUser?.email,
                avatarUrl: selectedUser?.avatarUrl,
                schoolId: selectedUser?.schoolId || '',
                organization: currentUser?.organization,
                schoolGrades: selectedUser?.schoolGrades,
                districtId: selectedUser?.districtId || '',
                witsbyContractGroupId: selectedUser?.witsbyContractGroupId,
              },
            },
          ],
        },
      },
    });
  };

  const blockedUsers: string[] = get(currentUser, 'preferences.chat.blockedUsers', []);

  const participants = searchValue
    ? defaultParticipants.filter(
        (c) =>
          c.name.toLowerCase().includes(trim(searchValue).toLowerCase()) ||
          c.email.toLowerCase().includes(trim(searchValue).toLowerCase()),
      )
    : defaultParticipants;

  const handleRequestSort = (_event: MouseEvent<unknown>, property: keyof IUserInfo) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleChangePage = (_event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = Math.max(0, (1 + page) * rowsPerPage - participants.length);

  const visibleRows = useMemo(
    () =>
      _orderBy(participants, [orderBy], [order]).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, participants, rowsPerPage],
  );

  return (
    <Box
      data-testid="participants-list"
      sx={(theme) => ({
        height: '100%',
        '& .MuiTableBody-root .MuiTableCell-root': {
          height: '100%',
          border: 0,
        },
        '& .MuiFormControl-root.MuiTextField-root': {
          mt: 0.2,
          mb: 4,
          maxWidth: '100%',
          '.MuiInputBase-root': {
            height: '35px !important',
            flexDirection: 'row-reverse',
          },
          '.MuiOutlinedInput-input': {
            p: 0,
          },
          '.MuiSvgIcon-root': {
            fontSize: theme.font.size.LARGE,
          },
          fieldset: {
            borderRadius: theme.border.radius.REGULAR,
          },
        },
      })}>
      <SearchBar
        sx={{
          '&.MuiFormControl-root.MuiTextField-root': {
            mb: 1,
          },
        }}
        placeholder="Search..."
        value={searchValue || ''}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          setSearchValue(e.target.value);
        }}
      />
      <TableContainer
        sx={{
          maxHeight: showBlockUser ? 'calc(100% - 32px - 35px)' : 'calc(100% - 32px - 100px)',
          '&::-webkit-scrollbar': {
            height: '5px',
          },
        }}>
        <Table size="small" stickyHeader>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            isPopUpChat={isPopUpChat}
            showBlockUser={showBlockUser}
            onRequestSort={handleRequestSort}
          />
          <TableBody sx={{ overflowY: 'auto' }}>
            {visibleRows.map((row) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.id}
                sx={(theme) => ({
                  [theme.breakpoints.down('md')]: {
                    td: {
                      p: 0.5,
                    },
                  },
                })}>
                <TableCell
                  scope="row"
                  sx={(theme) => ({
                    color: theme.palette.grey[700],
                    fontSize: theme.font.size.SMALL,
                    fontWeight: theme.font.weight.REGULAR,
                    'span.blank-span': {
                      pl: 1,
                    },
                  })}>
                  <Box
                    sx={{
                      display: 'flex',
                      cursor: 'pointer',
                      alignItems: 'center',
                    }}
                    onClick={() => handleDirectMessage(row)}>
                    <Box>
                      <Avatar
                        alt={row.name}
                        showOnlineStatus
                        src={row.avatarUrl}
                        sx={(theme) => ({
                          width: theme.size.LARGE,
                          height: theme.size.LARGE,
                          fontSize: theme.font.size.SMALL,
                        })}
                        isOnline={onlineUsers.includes(row.id)}
                      />
                      <span className="blank-span" />
                    </Box>
                    {row.name}
                  </Box>
                </TableCell>
                <TableCell align="center">
                  {row.id !== currentUser.oktaId && !blockedUsers?.includes(row.id) ? (
                    <IconButton
                      data-testid="message-button"
                      onClick={() => handleDirectMessage(row)}>
                      <ChatOutlinedIcon
                        sx={(theme) => ({
                          color: theme.palette.grey[200],
                          fontSize: theme.font.size.LARGE,
                        })}
                      />
                    </IconButton>
                  ) : (
                    <Box height={36} />
                  )}
                </TableCell>
                {!isPopUpChat && (
                  <TableCell
                    sx={(theme) => ({
                      color: theme.palette.grey[200],
                      fontSize: theme.font.size.SMALL,
                      fontWeight: theme.font.weight.REGULAR,
                      '& a': {
                        color: 'inherit',
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline',
                          color: theme.palette.primary.main,
                        },
                      },
                    })}>
                    <Link href={`mailto:${row.email}`} target="_blank">
                      {row.email}
                    </Link>
                  </TableCell>
                )}
                {showBlockUser && (
                  <TableCell align="center">
                    {row.id !== currentUser.oktaId && (
                      <Switch
                        size="small"
                        data-testid="block-button"
                        className="Mui-focusVisible"
                        checked={blockedUsers?.includes(row.id)}
                        sx={{
                          '& .MuiSwitch-switchBase.Mui-focusVisible .MuiSwitch-thumb': {
                            border: 'inherit',
                          },
                        }}
                        onChange={() => {
                          updateBlockUnBlockUser({ variables: { oktaId: row.id } });
                        }}
                      />
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default Participants;

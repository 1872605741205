import { useContext, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { IConversation, Loader } from '@ascd/witsby-components';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  List,
  styled,
  BoxProps,
  Collapse,
  IconButton,
  Box as MuiBox,
  ListSubheader,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { ADMIN_ROUTES } from '@constants';
import { AppContext } from '@contexts';
import { ChatContext, eChatActionType } from '@contexts/chatContext';
import { detectDeviceIsMobile } from '@utils';
import { ConversationItem } from './ConversationItem';
import { NoConversationFound } from './NoConversationFound';

const Box = styled(MuiBox)<BoxProps>(({ theme }) => ({
  fontFamily: [theme.font.family.PRIMARY].join(','),
}));

export interface IConversationsProps extends BoxProps {
  title: string;
  loading?: boolean;
  isBlocked?: boolean;
  isArchived?: boolean;
  isGroupMessage?: boolean;
  isDirectMessage?: boolean;
  isAssignmentMessage?: boolean;
  data: {
    totalCount: number;
    totalUnreadCount: number;
    conversations: IConversation[];
    unreadCountByConversations: { [key: string]: number };
  };
}

const Conversations = ({
  data,
  title,
  loading = true,
  isBlocked = false,
  isArchived = false,
  isGroupMessage = false,
  isDirectMessage = false,
  isAssignmentMessage = false,
  ...restProps
}: IConversationsProps): JSX.Element => {
  const router = useRouter();
  const isMobile = detectDeviceIsMobile();
  const {
    state: {
      currentUserRole: { isInstitutionalAdmin, isManager },
    },
  } = useContext(AppContext);
  const lastElementRef = useRef<HTMLElement>(null);
  const {
    dispatch,
    state: { globalChatSearch, detailsPage },
  } = useContext(ChatContext);

  const isPopUpChat = router.pathname !== '/chat-and-communication';

  const [isOpen, setIsOpen] = useState(!isArchived && !isBlocked);

  const { conversations: defaultConversations = [], unreadCountByConversations } = data;

  const conversations = globalChatSearch
    ? defaultConversations?.filter((c) =>
        c.title.toLowerCase().includes(trim(globalChatSearch).toLowerCase()),
      )
    : defaultConversations;

  let activeConversations;
  if (isArchived) {
    activeConversations = conversations?.filter((c) => c.status === 'INACTIVE');
  } else {
    activeConversations = conversations?.filter((c) => c.status === 'ACTIVE');
  }

  return (
    <Box
      sx={{
        '&:not(:first-child)': {
          mt: isPopUpChat ? 1 : 1.5,
        },
        ...(isPopUpChat && {
          '&:not(:last-child)': {
            pb: 0.7,
            borderBottom: '1px solid #dcdada78',
          },
        }),
      }}
      {...restProps}>
      <List
        sx={(theme) => ({
          ...(!isPopUpChat && {
            borderStyle: 'solid',
            borderWidth: theme.border.width[1],
            borderColor: theme.palette.grey[500],
            borderRadius: theme.border.radius.REGULAR,
          }),
          '& .MuiListItemText-root span': {
            fontSize: theme.font.size.REGULAR,
          },
          ...(!isOpen && {
            p: 0,
          }),
          ...(isPopUpChat && {
            pb: 0,
          }),
        })}
        subheader={
          <ListSubheader
            disableSticky
            onClick={() => {
              if ((isArchived || isBlocked) && !isOpen) {
                setTimeout(() => {
                  if (lastElementRef?.current) {
                    lastElementRef.current.scrollIntoView({ behavior: 'smooth' });
                  }
                }, 250);
              }
              setIsOpen(!isOpen);
            }}
            sx={(theme) => ({
              px: 0.5,
              display: 'flex',
              cursor: 'pointer',
              overflow: 'hidden',
              alignItems: 'center',
              borderRadius: '2rem',
              justifyContent: 'space-between',
              color: theme.palette.primary.main,
              fontSize: theme.font.size.REGULAR,
              fontWeight: theme.font.weight.REGULAR,
              svg: {
                color: theme.palette.primary.main,
                ...(isOpen && {
                  '&.arrow-icon': {
                    transform: 'rotate(180deg)',
                  },
                }),
              },
              ...(isPopUpChat && {
                lineHeight: theme.font.size.SMALL,
                backgroundColor: 'transparent',
              }),
            })}>
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                ...((isArchived || isBlocked) && {
                  '&, svg': {
                    color: theme.palette.grey[200],
                  },
                }),
              })}>
              <ArrowDropDownIcon className="arrow-icon" />
              {title}
              {isArchived || isBlocked ? '' : 's'}
            </Box>
            {(isDirectMessage ||
              (isGroupMessage && (isInstitutionalAdmin || isManager) && !isMobile) ||
              (isAssignmentMessage && (isInstitutionalAdmin || isManager) && !isMobile)) && (
              <IconButton
                aria-label="Add participants"
                className="conversation-item"
                sx={(theme) => ({
                  svg: {
                    color: theme.palette.grey[200],
                    fontSize: isPopUpChat ? theme.font.size.LARGE : theme.font.size.X_LARGE,
                  },
                })}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (isDirectMessage) {
                    dispatch({
                      data: {
                        ...detailsPage,
                        type: 'CHOOSE_PARTICIPANT',
                      },
                      type: 'DETAILS_PAGE' as eChatActionType.DETAILS_PAGE,
                    });
                  }
                  if (isGroupMessage && (isInstitutionalAdmin || isManager)) {
                    router.push(`${ADMIN_ROUTES.MANAGE_GROUPS}?type=CREATE&closeChat=true`);
                  }
                  if (isAssignmentMessage && (isInstitutionalAdmin || isManager)) {
                    router.push(`${ADMIN_ROUTES.ASSIGNMENT}/new?closeChat=true`);
                  }
                }}>
                <AddIcon className="add-icon" />
              </IconButton>
            )}
          </ListSubheader>
        }>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          {loading && (
            <Box
              sx={{
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                minHeight: isPopUpChat ? 20 : 100,
              }}>
              <Loader isFullScreen size={20} />
            </Box>
          )}
          {!loading && isEmpty(activeConversations) && (
            <NoConversationFound text={`No ${title.toLowerCase()} chats available`} />
          )}
          {activeConversations?.map((conversation) => (
            <ConversationItem
              key={`${conversation._id}`}
              conversation={conversation}
              isDisabled={isArchived || isBlocked}
              isDirectMessage={isDirectMessage || isBlocked}
              unreadCountByConversations={unreadCountByConversations}
            />
          ))}
          <Box ref={lastElementRef} />
        </Collapse>
      </List>
    </Box>
  );
};

export default Conversations;

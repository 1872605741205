import { useContext, useEffect, useRef, useState, MouseEvent } from 'react';
import { Router, useRouter } from 'next/router';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import { IConversation, IMessage, SvgIcon } from '@ascd/witsby-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Grid,
  Badge,
  Tooltip,
  Popover,
  useTheme,
  IconButton,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Rnd, RndResizeCallback, RndDragCallback } from 'react-rnd';
import { AppContext, SocketContext } from '@contexts';
import { ChatContext, eChatActionType } from '@contexts/chatContext';
import GET_UNREAD_COUNT_BY_USER_ID from '@graphql/schema/getUnreadCountByUserId.graphql';
import CONVERSATION from '@graphql/schema/conversation.graphql';
import { getChatTheme } from '@utils';
import { Chat } from '../Chat';
import { ChatDetails } from '../ChatDetails';
import { ChatSettings } from '../ChatSettings';
import { ChatSidebar } from '../ChatSidebar';
import { ChatSocket } from '../ChatSocket';
import { ChooseParticipant } from '../ChooseParticipant';
import { SelectConversation } from '../SelectConversation';

const ChatAndCommunicationPopup = () => {
  const theme = useTheme();
  const router = useRouter();
  const chatTheme = getChatTheme(theme);
  const apolloClient = useApolloClient();
  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  const minimumWidth = 590;
  const windowWidth = window.innerWidth;

  const [openFilter, setOpenFilter] = useState(true);
  const [defaultWidth, setDefaultWidth] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [defaultMinWidth, setDefaultMinWidth] = useState(590);
  const [sidebarWidth, setSidebarWidth] = useState<string>('');
  const [width, setWidth] = useState<number>(windowWidth * 0.4);
  const [popoverPositionY, setPopoverPositionY] = useState<number>(0);
  const [popoverPositionX, setPopoverPositionX] = useState<number>(0);
  const [height, setHeight] = useState<number>(window.innerHeight * 0.6);

  const isInitialRender = useRef(true);
  const chatRef = useRef<HTMLDivElement>(null);
  const startXRef = useRef<number | null>(null);
  const startWidthRef = useRef<number | null>(null);

  const minContentWidth = 400;
  const minChatSidebarWidth = 200;
  const initialWidth = windowWidth * 0.4;
  const minSidebarWidth = initialWidth * 0.35;
  const minWidthChat = !openFilter ? 400 : minimumWidth;
  const minSidebarWidthPercentage = (minSidebarWidth / initialWidth) * 100;

  useEffect(() => {
    setSidebarWidth(`${minSidebarWidthPercentage}%`);
  }, []);

  const { socket } = useContext(SocketContext);
  const {
    dispatch,
    state: { detailsPage },
  } = useContext(ChatContext);
  const {
    state: { currentUser, currentInstitution },
  } = useContext(AppContext);

  const chatAndCommunication = get(currentInstitution, 'settings.chatAndCommunication', true);
  const isChatAndCommunicationEnable = chatAndCommunication !== false;

  useEffect(() => {
    const handleRouteChange = () => {
      if (router?.query?.closeChat === 'true') {
        setIsPopoverOpen(false);
      }
    };

    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.query.closeChat]);

  const [getConversation] = useLazyQuery(CONVERSATION, {
    onCompleted: (data) => {
      const conversation: IConversation = get(data, 'conversation', {});
      if (!isEmpty(conversation)) {
        let title = '';
        let avatar = '';
        if (conversation.type === 'DIRECT') {
          const userName = conversation.participants.find(
            (participant) => participant.userInfo.id !== currentUser.oktaId,
          );

          if (userName) {
            title = userName.userInfo.name || conversation.title || '';
            avatar = userName.userInfo?.avatarUrl || conversation?.avatar || '';
          }
        }
        dispatch({
          data: {
            type: 'CHAT',
            detailsPageData: {
              conversation: {
                ...conversation,
                ...(conversation.type === 'DIRECT' && {
                  title,
                  avatar,
                }),
              },
            },
          },
          type: 'DETAILS_PAGE' as eChatActionType.DETAILS_PAGE,
        });
      }
    },
    fetchPolicy: 'network-only',
  });

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    startXRef.current = e.clientX;
    const sidebarElement = document.getElementById('sidebar');
    const computedStyle = sidebarElement ? window.getComputedStyle(sidebarElement) : null;
    startWidthRef.current = computedStyle ? parseInt(computedStyle.width, 10) : null;

    const handleMouseMove = (moveEvent: MouseEvent) => {
      if (!startXRef.current || !startWidthRef.current) return;
      const newWidth = startWidthRef.current + moveEvent.clientX - startXRef.current;
      const containerElement = document.getElementById('container');
      const parentWidth = containerElement?.clientWidth || 0;
      const newSidebarWidthPercentage = (newWidth / parentWidth) * 100;

      const newContentWidth = parentWidth - newWidth;

      if (
        newWidth >= minChatSidebarWidth &&
        newContentWidth >= minContentWidth &&
        newSidebarWidthPercentage <= 30
      ) {
        setSidebarWidth(`${newSidebarWidthPercentage}%`);
      } else if (newWidth < minChatSidebarWidth) {
        setSidebarWidth(`${(minChatSidebarWidth / parentWidth) * 100}%`);
      }
    };

    const handleMouseUp = () => {
      startXRef.current = null;
      startWidthRef.current = null;
      document.removeEventListener('mousemove', handleMouseMove as unknown as EventListener);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove as unknown as EventListener);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const { data, refetch } = useQuery(GET_UNREAD_COUNT_BY_USER_ID, {
    variables: {
      userId: currentUser.oktaId,
    },
    fetchPolicy: 'network-only',
  });

  const unreadCounts = get(data, 'getUnreadCountByUserId', 0);
  const activeConversation: IConversation = get(
    detailsPage,
    'detailsPageData.conversation',
    {},
  ) as IConversation;

  const notifyInfo = get(currentUser, 'preferences.chat.notificationPreferences', {
    messageBadge: true,
    soundNotification: true,
    emailNotification: false,
  });

  useEffect(() => {
    const prevDimensions = {
      width: windowWidth,
      height: window.innerHeight,
    };
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const preWidth = windowWidth * 0.4;
      if (windowWidth !== prevDimensions.width) {
        if (preWidth < minimumWidth) {
          setPopoverPositionX(document.body.clientWidth - minWidthChat - 20);
        } else {
          setPopoverPositionX(document.body.clientWidth - preWidth - 20);
        }
        setWidth(width * 0.4);
      }
      if (windowHeight !== prevDimensions.height) {
        setPopoverPositionY(windowHeight - windowHeight * 0.6 - 150);
        setHeight(windowHeight * 0.6);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else if (!openFilter) {
      setDefaultMinWidth(380);
      setWidth((prevWidth) => prevWidth - (parseFloat(sidebarWidth) / 100) * width);
      setPopoverPositionX(popoverPositionX + (parseFloat(sidebarWidth) / 100) * width);
      setDefaultWidth((parseFloat(sidebarWidth) / 100) * width);
      setSidebarWidth('0%');
    } else {
      const padding = 50;
      let newWidth = width + defaultWidth;
      if (newWidth >= windowWidth - padding) {
        newWidth = windowWidth - padding;
      }
      let newX = popoverPositionX - defaultWidth;

      if (newX > windowWidth) {
        newX = Math.max(0, windowWidth - newWidth);
      }
      if (newX < 0) {
        newX = 0;
      }
      setDefaultMinWidth(minimumWidth);
      setWidth(newWidth);
      setSidebarWidth(`${minSidebarWidthPercentage}%`);
      setPopoverPositionX(newX);
    }
    setSidebarOpen(true);
  }, [openFilter]);

  useEffect(() => {
    if (socket) {
      // onMessage
      socket.on('onMessage', (createdMessage: IMessage) => {
        if (notifyInfo?.soundNotification) {
          if (!isPopoverOpen || activeConversation?._id !== createdMessage?.conversationId) {
            const sound = new Audio('/notification.mp3');
            sound.play();
          }
        }

        // Read the cached getUnreadCountByUserId data for the current user
        const cachedGetUnreadCountByUserId = apolloClient.readQuery({
          query: GET_UNREAD_COUNT_BY_USER_ID,
          variables: {
            userId: currentUser.oktaId,
          },
        });

        let getUnreadCountByUserId = get(cachedGetUnreadCountByUserId, 'getUnreadCountByUserId', 0);
        getUnreadCountByUserId += 1;

        // Write the updated unread counts back to the cache
        apolloClient.writeQuery({
          query: GET_UNREAD_COUNT_BY_USER_ID,
          variables: {
            userId: currentUser.oktaId,
          },
          data: {
            ...cachedGetUnreadCountByUserId,
            getUnreadCountByUserId,
          },
        });
      });
    }

    return () => {
      if (socket) {
        socket.off('onMessage');
      }
    };
  }, [
    activeConversation?._id,
    apolloClient,
    currentUser.oktaId,
    isPopoverOpen,
    notifyInfo?.soundNotification,
    socket,
  ]);

  if (!isChatAndCommunicationEnable) return null;

  const lastActiveConversationId = get(currentUser, 'preferences.chat.lastActiveConversation', '');

  const handleClose = () => {
    setIsPopoverOpen(false);
  };

  const getDetailsReports = () => {
    switch (detailsPage?.type) {
      case 'CHAT':
        return <Chat />;
      case 'CHAT_DETAILS':
        return <ChatDetails />;
      case 'CHAT_SETTINGS':
        return <ChatSettings />;
      case 'CHOOSE_PARTICIPANT':
        return <ChooseParticipant />;
      default:
        return <SelectConversation />;
    }
  };

  const handleResizeStop: RndResizeCallback = (_e, direction, ref, delta, pos) => {
    if (direction.includes('left')) {
      const newWidth = parseInt(ref.style.width, 10);
      let newPosX = pos.x;
      if (newPosX + newWidth > windowWidth) {
        newPosX = windowWidth - newWidth;
      }
      if (newPosX < 0) {
        newPosX = 0;
      }
      setWidth(newWidth);
      setPopoverPositionX(newPosX);
    }
  };

  const handleResize: RndResizeCallback = (_e, direction, ref, delta, pos) => {
    if (!direction.includes('left')) {
      const newWidth = parseInt(ref.style.width, 10);
      const newHeight = parseInt(ref.style.height, 10);
      let newPosX = pos.x;
      let newPosY = pos.y;

      if (newPosX + newWidth > windowWidth) {
        newPosX = windowWidth - newWidth;
      }
      if (newPosY + newHeight > window.innerHeight) {
        newPosY = window.innerHeight - newHeight;
      }
      if (newPosX < 0) {
        newPosX = 0;
      }
      if (newPosY < 0) {
        newPosY = 0;
      }

      setWidth(newWidth);
      setPopoverPositionX(newPosX);
      setHeight(newHeight);
      setPopoverPositionY(newPosY);
    }
  };

  const goToChatAndCommunication = (newTab = false) => {
    if (newTab) {
      window.open(
        `/chat-and-communication${detailsPage?.type ? `?chatDetails=${detailsPage?.type}` : ''}`,
        '_blank',
        'noopener, noreferrer',
      );
    } else {
      router.push({
        query: { redirectTo: router.asPath },
        pathname: '/chat-and-communication',
      });
    }
  };

  const handleShowConversationList = () => {
    dispatch({
      data: {},
      type: 'DETAILS_PAGE' as eChatActionType.RESET,
    });
  };
  const handleDrag: RndDragCallback = (_e, d) => {
    setPopoverPositionX(d.x);
    setPopoverPositionY(d.y);
  };

  const renderContent = () => (
    <Box
      sx={{
        overflow: 'hidden',
        width: '100%',
        height: '100%',
      }}>
      <ChatSocket />
      <Box
        className="drag-handle"
        sx={{
          p: 1.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: theme.palette.white[100],
          fontWeight: theme.font.weight.BOLD,
          backgroundColor: theme.palette.primary.light,
          borderTopLeftRadius: theme.border.radius.LARGE,
          borderTopRightRadius: theme.border.radius.LARGE,
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ArrowBackIcon
            tabIndex={0}
            onClick={handleShowConversationList}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                handleShowConversationList();
              }
            }}
            sx={{
              mr: 1,
              cursor: 'pointer',
              fontSize: theme.font.size.LARGE,
              '&:focus': {
                borderRadius: '50%',
                outlineColor: theme.palette.primary.main,
              },
              ...(!detailsPage?.type && {
                [theme.breakpoints.down('md')]: { display: 'none' },
              }),
              [theme.breakpoints.up('sm')]: { display: 'none' },
            }}
          />
          Chat & Communication
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <SvgIcon
            tabIndex={0}
            icon="CLICK_ICON"
            data-testid="new-tab-icon"
            onClick={() => goToChatAndCommunication(true)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                goToChatAndCommunication(true);
              }
            }}
            sx={{
              mr: 0.8,
              cursor: 'pointer',
              fontSize: theme.font.size.LARGE,
              path: { fill: theme.palette.white[100] },
              '&:focus': {
                borderRadius: '50%',
                outlineColor: theme.palette.primary.main,
              },
              '@media only screen and (max-width: 600px)': {
                display: 'none',
              },
            }}
          />
          <CloseOutlinedIcon
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                handleClose();
              }
            }}
            data-testid="close-popup-button"
            sx={{
              cursor: 'pointer',
              color: theme.palette.grey[700],
              fontSize: theme.font.size.LARGE,
              background: theme.palette.white[100],
              borderRadius: theme.border.radius.FULL,
              '&:focus': {
                borderRadius: '50%',
                outlineColor: theme.palette.primary.main,
              },
            }}
          />
        </Box>
      </Box>
      <Grid
        container
        spacing={0}
        id="container"
        display="grid"
        sx={{
          minHeight: 350,
          gridTemplateColumns: { xs: '100%', sm: `${sidebarWidth} calc(100% - ${sidebarWidth})` },
          height: `calc(${height}px - 49px)`,
          width: '100%',
        }}>
        <Box
          id="sidebar"
          sx={{
            position: 'relative',
            display: (detailsPage?.type && isSmallScreen) || !sidebarOpen ? 'none' : 'block',
            gridColumn: { xs: '1 / -1', sm: '1' },
          }}>
          <Box
            sx={{
              display: isSmallScreen ? 'none' : 'block',
              position: 'absolute',
              top: '35px',
              zIndex: 999,
              ...(openFilter && { right: 0 }),
              ...(!openFilter && { left: -25 }),
            }}>
            <Tooltip
              PopperProps={{
                style: {
                  zIndex: 11111,
                },
              }}
              describeChild
              title="Sidebar ChatPopup"
              arrow>
              <Box
                onClick={() => {
                  setSidebarOpen(false);
                  if (sidebarWidth <= `${minSidebarWidthPercentage}%`) {
                    setOpenFilter(!openFilter);
                  } else {
                    setSidebarWidth(`${minSidebarWidthPercentage}%`);
                  }
                }}
                sx={{
                  pb: '2.5px',
                  fontSize: '1rem',
                  cursor: 'pointer',
                  boxShadow: '0px 3px 8px 2px rgba(0, 0, 0, 0.14)',
                  transition: '0.1s',
                  '& svg': { width: '0.8em', height: '0.8em', verticalAlign: 'middle' },
                  background: theme.palette.primary.main,
                  ...(openFilter
                    ? {
                        borderTopLeftRadius: theme.border.radius.REGULAR,
                        borderBottomLeftRadius: theme.border.radius.REGULAR,
                      }
                    : {
                        borderTopRightRadius: theme.border.radius.REGULAR,
                        borderBottomRightRadius: theme.border.radius.REGULAR,
                        ml: '25px',
                      }),
                }}>
                <KeyboardArrowRightIcon
                  sx={{
                    color: '#FFFF',
                    transform: openFilter ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </Box>
            </Tooltip>
          </Box>

          <Box
            sx={{
              overflow: 'hidden',
              borderBottomLeftRadius: theme.border.radius.LARGE,
              borderBottom: 'none !important',
              minHeight: 350,
              borderStyle: 'solid',
              backgroundColor: '#F6F6F6',
              borderWidth: theme.border.width[1],
              borderColor: theme.palette.grey[500],
              height: isSmallScreen ? '100vh' : `calc(${height}px - 49px)`,
            }}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: openFilter ? 'block' : 'none',
              }}>
              <ChatSidebar />
            </Box>

            <Box
              sx={{
                width: 26,
                position: 'absolute',
                cursor: 'col-resize',
                top: 0,
                right: -12,
                bottom: 0,
                display: openFilter ? 'block' : 'none',
                '&:hover .resize-line': {
                  boxShadow: '2px -1px 5px 0px rgba(0,0,0,0.13)',
                  background: theme.palette.primary.main,
                  width: 4,
                },
              }}
              onMouseDown={handleMouseDown}>
              <Box
                className="resize-line"
                sx={{
                  width: '1px',
                  cursor: 'col-resize',
                  position: 'absolute',
                  top: 0,
                  right: 12,
                  bottom: 0,
                  display: openFilter ? 'block' : 'none',
                  background: theme.palette.grey.A200,
                  ':hover': {
                    boxShadow: '2px -1px 5px 0px rgba(0,0,0,0.13)',
                    background: theme.palette.primary.main,
                    width: 4,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          id="contentbar"
          sx={{
            gridColumn: { xs: '1 / -1', sm: '2' },
            display: !detailsPage?.type && isSmallScreen ? 'none' : 'block',
          }}>
          <Box
            sx={{
              minHeight: 350,
              height: isSmallScreen ? 'calc(100vh - 3rem)' : `calc(${height}px - 49px)`,
            }}>
            {getDetailsReports()}
          </Box>
        </Box>
      </Grid>
    </Box>
  );

  return (
    <ThemeProvider theme={chatTheme}>
      <IconButton
        tabIndex={0}
        data-testid="open-popup-button"
        sx={{
          right: 10,
          bottom: 110,
          zIndex: 99999,
          cursor: 'pointer',
          position: 'fixed',
          svg: {
            fontSize: theme.font.size.H2,
          },
          ...(isPopoverOpen && {
            visibility: 'hidden',
            '@media only screen and (max-width: 600px)': {
              display: 'none',
            },
          }),
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          if (isPopoverOpen) {
            refetch();
            handleClose();
          } else {
            if (lastActiveConversationId === activeConversation?._id) {
              getConversation({ variables: { conversationId: lastActiveConversationId } });
            }
            const padding = 20;
            const checkWidth = windowWidth * 0.4;
            if (checkWidth < minimumWidth) {
              const centerX = document.body.clientWidth - minWidthChat - padding;
              const centerY = window.innerHeight - height - 150;
              setPopoverPositionX(centerX);
              setPopoverPositionY(centerY);
            } else {
              let posY = window.innerHeight - height - 150;
              if (posY < padding) {
                posY = padding;
              }
              setPopoverPositionX(document.body.clientWidth - width - padding);
              setPopoverPositionY(posY);
            }
            setIsPopoverOpen(true);
          }
        }}>
        <Badge
          color="secondary"
          badgeContent={unreadCounts}
          invisible={notifyInfo?.messageBadge ? isPopoverOpen : false}
          sx={{
            '& .MuiBadge-badge': {
              fontSize: theme.font.size.X_SMALL,
            },
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <SvgIcon icon="CHAT_ICON" sx={{ circle: { fill: theme.palette.primary.main } }} />
        </Badge>
      </IconButton>

      {isPopoverOpen && !isSmallScreen && (
        <Box
          sx={{
            position: 'fixed',
            zIndex: 11111,
          }}>
          <Rnd
            data-testid="chat-popup"
            maxWidth={windowWidth - 50}
            maxHeight={window.innerHeight - 50}
            minWidth={defaultMinWidth}
            minHeight={400}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: theme.palette.white[100],
              borderRadius: theme.border.radius.LARGE,
              boxShadow: theme.boxShadow.LARGE,
            }}
            onDragStop={handleDrag}
            dragHandleClassName="drag-handle"
            bounds="window"
            onResize={handleResize}
            onResizeStop={handleResizeStop}
            size={{ width, height }}
            position={{ x: popoverPositionX, y: popoverPositionY }}
            default={{
              x: popoverPositionX,
              y: popoverPositionY,
              width,
              height,
            }}>
            {renderContent()}
          </Rnd>
        </Box>
      )}
      {isPopoverOpen && isSmallScreen && (
        <Popover
          ref={chatRef}
          open={isPopoverOpen}
          disableScrollLock
          anchorReference="anchorPosition"
          anchorPosition={{ top: popoverPositionY, left: popoverPositionX }}
          onClose={handleClose}
          data-testid="chat-popup"
          slotProps={{
            paper: {
              sx: {
                width,
                height,
                maxWidth: windowWidth,
                overflowY: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: theme.border.radius.LARGE,
                minWidth: 600,
                '@media only screen and (max-width: 600px)': {
                  minWidth: '100%',
                  maxWidth: '100%',
                  minHeight: '100%',
                  maxHeight: '100%',
                  top: '0 !important',
                  left: '0 !important',
                  borderRadius: theme.border.radius.NONE,
                },
              },
            },
          }}>
          {renderContent()}
        </Popover>
      )}
    </ThemeProvider>
  );
};

export default ChatAndCommunicationPopup;

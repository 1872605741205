import { ReactNode, createContext, Dispatch, SetStateAction, useState, useMemo } from 'react';

interface DrawerWidthContextType {
  drawerWidth: number;
  setDrawerWidth: Dispatch<SetStateAction<number>>;
}

const DrawerWidthContext = createContext<DrawerWidthContextType>({
  drawerWidth: 240,
  setDrawerWidth: () => null,
});

const DrawerContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [drawerWidth, setDrawerWidth] = useState(240);
  const value = useMemo(() => ({ drawerWidth, setDrawerWidth }), [drawerWidth]);
  return <DrawerWidthContext.Provider value={value}>{children}</DrawerWidthContext.Provider>;
};
export { DrawerWidthContext, DrawerContextProvider };

import { useState, useEffect, useCallback, useContext } from 'react';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
// import MINIMAL_SCHOOLS from '@graphql/schema/getMinimalSchools.graphql';
import { ISalesforceUser } from '@ascd/witsby-components';
import get from 'lodash/get';
import { useDropzone } from 'react-dropzone';
import { PROFILE_FORM_TYPE } from '@constants';
import { AppContext } from '@contexts/appContext';
import GET_SALES_FORCE_USER from '@graphql/schema/getSalesforceUser.graphql';
import PROFILE_CATEGORIES from '@graphql/schema/getUserProfileCategories.graphql';
import UPDATE_SALESFORCE_USER from '@graphql/schema/mutations/updateSalesforceUsers.graphql';
import GET_NEW_IMAGE_UPLOAD_URL from '@graphql/schema/newImageUploadUrl.graphql';
import handleGraphqlError from '@utils/handleGraphqlError';
import { showToast } from '@utils/toast';
import uploadFile from '@utils/uploadFile';
import usePresignedImage from './usePresignedImage';

const useProfileForm = (formType: string) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);

  const apolloClient = useApolloClient();
  const [avatarUrl, setAvatarUrl] = useState('');
  const [presignedProfileUrl] = usePresignedImage(avatarUrl);
  const [isAvatarUploading, setIsAvatarUploading] = useState(false);
  const [editMode, setEditMode] = useState(formType === PROFILE_FORM_TYPE.UPDATE);
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  const [personalEmail, setPersonalEmail] = useState('');
  const [initialYearOfService, setInitialYearOfService] = useState(2000);
  const [district, setDistrict] = useState<{ name: string; id: string }>({ name: '', id: '' });
  const [school, setSchool] = useState<{ name: string; id: string }>({ name: '', id: '' });
  const [title, setTitle] = useState('');
  const [jobType, setJobType] = useState('');
  const [enumeratedJobTypes, setEnumeratedJobTypes] = useState<string[]>([]);
  const [enumeratedGrades, setEnumeratedGrades] = useState<string[]>([]);
  const [enumeratedSubjects, setEnumeratedSubjects] = useState<string[]>([]);
  const [enumeratedRoles, setEnumeratedRoles] = useState<string[]>([]);
  /** @todo: schools */
  // const [enumeratedSchools, setEnumeratedSchools] = useState<{ name: string; id: string }[]>([]);
  const [grades, setGrades] = useState<string[]>([]);
  const [subjectAreas, setSubjectAreas] = useState<string[]>([]);
  const [formWitsbyRole, setWitsbyRole] = useState<string[]>([]);
  const [weeklyGoal, setWeeklyGoal] = useState(0);
  const [modalOff, setModalOff] = useState(true);

  const [getSalesForceUserDetails] = useLazyQuery(GET_SALES_FORCE_USER, {
    onCompleted: (data) => {
      const salesforceUser: ISalesforceUser = get(data, 'getSalesforceUser');
      const {
        avatar: profileAvatar = '',
        email: profileEmail = '',
        firstName: profileGivenName = '',
        lastName: profileFamilyName = '',
        recoveryEmail: profilePersonalEmail = '',
        districtId: profileDistrictId = '',
        districtName: profileDistrictName = '',
        schoolId: profileSchoolId = '',
        schoolName: profileSchoolName = '',
        initialYearOfService: profileInitialYearOfService = 2000,
        title: profileTitle = '',
        jobType: profileJobType = '',
        grades: profileGrades = [],
        subjectAreas: profileSubjectAreas = [],
        witsbyRole: profileWitsbyRole = [],
        minutesPerWeek: profileWeeklyGoal = 0,
        modalOff: profileModalOff = false,
      } = salesforceUser;
      setAvatarUrl(profileAvatar || '');
      setGivenName(profileGivenName || '');
      setFamilyName(profileFamilyName || '');
      setEmail(profileEmail || '');
      setPersonalEmail(profilePersonalEmail || '');
      setDistrict({ name: profileDistrictName || '', id: profileDistrictId || '' });
      setSchool({ name: profileSchoolName || '', id: profileSchoolId || '' });
      setInitialYearOfService((profileInitialYearOfService as number) || 2000);
      setTitle(profileTitle || '');
      setJobType(profileJobType || '');
      setGrades((profileGrades ?? []) as string[]);
      setSubjectAreas((profileSubjectAreas ?? []) as string[]);
      setWitsbyRole(profileWitsbyRole ?? []);
      setWeeklyGoal(profileWeeklyGoal || 0);
      setModalOff(profileModalOff || false);
    },
    fetchPolicy: 'network-only',
  });

  /** @todo: get enumerated schools */
  //   const [getSchools] = useLazyQuery(MINIMAL_SCHOOLS, {
  //     onCompleted: (completedData) => {
  //       const { schools } = completedData;
  //       setEnumeratedSchools(schools);
  //     },
  //     fetchPolicy: 'network-only',
  //   });

  const [getJobTypeCategories] = useLazyQuery(PROFILE_CATEGORIES, {
    onCompleted: (completedData) => {
      const {
        profileCategory: { enumeratedList },
      } = completedData;
      setEnumeratedJobTypes(enumeratedList);
    },
    fetchPolicy: 'network-only',
  });

  const [getSubjectAreaCategories] = useLazyQuery(PROFILE_CATEGORIES, {
    onCompleted: (completedData) => {
      const {
        profileCategory: { enumeratedList },
      } = completedData;
      setEnumeratedSubjects(enumeratedList);
    },
    fetchPolicy: 'network-only',
  });

  const [getSchoolGradesCategories] = useLazyQuery(PROFILE_CATEGORIES, {
    onCompleted: (completedData) => {
      const {
        profileCategory: { enumeratedList },
      } = completedData;
      setEnumeratedGrades(enumeratedList);
    },
    fetchPolicy: 'network-only',
  });

  const [getWitsbyRoleCategories] = useLazyQuery(PROFILE_CATEGORIES, {
    onCompleted: (completedData) => {
      const {
        profileCategory: { enumeratedList },
      } = completedData;
      setEnumeratedRoles(enumeratedList);
    },
    fetchPolicy: 'network-only',
  });

  const [updateSalesforceUser] = useMutation(UPDATE_SALESFORCE_USER);

  useEffect(() => {
    if (formType === PROFILE_FORM_TYPE.UPDATE)
      getSalesForceUserDetails({
        variables: {
          email: currentUser.email,
        },
      });
  }, [formType, getSalesForceUserDetails, currentUser.email]);

  /** @todo: get enumerated schools */
  //   useEffect(() => {
  //     getSchools();
  //   }, [getSchools]);

  useEffect(() => {
    getJobTypeCategories({
      variables: {
        profileCategory: 'jobType',
      },
    });
  }, [getJobTypeCategories]);

  useEffect(() => {
    getSubjectAreaCategories({
      variables: {
        profileCategory: 'subjectAreas',
      },
    });
  }, [getSubjectAreaCategories]);

  useEffect(() => {
    getSchoolGradesCategories({
      variables: {
        profileCategory: 'schoolGrades',
      },
    });
  }, [getSchoolGradesCategories]);

  useEffect(() => {
    getWitsbyRoleCategories({
      variables: {
        profileCategory: 'witsbyRole',
      },
    });
  }, [getWitsbyRoleCategories]);

  const clearProfileState = () => {
    setAvatarUrl('');
    setIsAvatarUploading(false);
    setGivenName('');
    setFamilyName('');
    setEmail('');
    setPersonalEmail('');
    setInitialYearOfService(2000);
    setDistrict({ name: '', id: '' });
    setSchool({ name: '', id: '' });
    setTitle('');
    setJobType('');
    setGrades([]);
    setSubjectAreas([]);
    setWitsbyRole([]);
    setWeeklyGoal(0);
    setModalOff(true);
  };

  /** @todo: Make app wide util */
  const generateUrlAndFileUpload = useCallback(
    async (file: File) => {
      const response = await apolloClient.query({
        query: GET_NEW_IMAGE_UPLOAD_URL,
        variables: { imageName: file.name },
        fetchPolicy: 'network-only',
      });
      const { key, url } = response.data.newImageUploadUrl;

      await uploadFile(url, file);
      return key;
    },
    [apolloClient],
  );

  /** @todo: Make app wide util */
  const onAvatarDrop = useCallback(
    async (files: File[]) => {
      if (files[0]) {
        try {
          setIsAvatarUploading(true);
          const iconSrc = await generateUrlAndFileUpload(files[0]);
          setIsAvatarUploading(false);
          setAvatarUrl(iconSrc);
        } catch {
          setIsAvatarUploading(false);
        }
      }
    },
    [generateUrlAndFileUpload],
  );

  const { getRootProps: avatarGetRootProps, getInputProps: avatarGetInputProps } = useDropzone({
    minSize: 0,
    maxFiles: 1,
    maxSize: 10242880,
    onDrop: onAvatarDrop,
    accept: { 'image/jpg': [], 'image/jpeg': [], 'image/png': [] },
  });

  const handleUpdateProfile = (userData: Partial<ISalesforceUser>) => {
    updateSalesforceUser({
      variables: {
        userData: {
          ...userData,
          lastName: userData?.lastName || currentUser.lastName,
        },
        email: currentUser.email,
      },
    })
      .then(() => showToast('Profile updated successfully!'))
      .catch(handleGraphqlError);
  };

  return {
    avatarUrl,
    setAvatarUrl,
    presignedProfileUrl,
    isAvatarUploading,
    setIsAvatarUploading,
    editMode,
    setEditMode,
    givenName,
    setGivenName,
    familyName,
    setFamilyName,
    email,
    setEmail,
    personalEmail,
    setPersonalEmail,
    initialYearOfService,
    setInitialYearOfService,
    district,
    setDistrict,
    school,
    setSchool,
    title,
    setTitle,
    jobType,
    setJobType,
    enumeratedJobTypes,
    enumeratedGrades,
    enumeratedSubjects,
    enumeratedRoles,
    /** @todo: schools */
    // enumeratedSchools,
    grades,
    setGrades,
    subjectAreas,
    setSubjectAreas,
    formWitsbyRole,
    setWitsbyRole,
    weeklyGoal,
    setWeeklyGoal,
    modalOff,
    setModalOff,
    avatarGetRootProps,
    avatarGetInputProps,
    handleUpdateProfile,
    clearProfileState,
  };
};

export default useProfileForm;

const uploadFile = async (url: string, file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async () => {
      const binaryData = Buffer.from(reader.result as string, 'binary');

      const response = await fetch(url, { method: 'PUT', body: binaryData });

      if (response?.ok && response?.status === 200) resolve(true);

      reject(response);
    };
  });

export default uploadFile;
